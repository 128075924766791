import { useEffect, useState, useContext, useRef } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import {
	Divider,
	FormControlLabel,
	FormHelperText,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Box,
	FormControl,
	Checkbox,
	MenuItem,
	Select,
	Switch,
	InputLabel,
	Table,
	TableHead,
	TableRow,
	TableCell,
	IconButton,
	TextField,
	Paper,
	Tooltip,
	CircularProgress,
	Typography,
	Backdrop,
	CardHeader,
	CardContent,
} from '@mui/material';
import {
	DeleteOutlined,
	AddCircleOutlineOutlined,
	InfoOutlined,
} from '@mui/icons-material';
import { object, string, mixed, date, array } from 'yup';
import { createCampaign, updateCampaign } from '../../graphql/mutations';
import { segmentByTenant, profileByTenant } from '../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { InContact } from '../../contexts/InContact';
import { Webex } from '../../contexts/Webex';
import { Card, TableBody } from '@aws-amplify/ui-react';
import { cardStyle, selectInputStyle, actionOneButtonStyle, actionTwoButtonStyle } from 'src/theme';
import { ConfirmDialog } from '../confirmDialog/confirmDialog';
import { fetchAuthSession } from 'aws-amplify/auth';
import { RingCX } from 'src/contexts/RingCX';
import { AmazonConnect } from 'src/contexts/Connect';
// import Nobelbiz from '../Context/Nobelbiz';
// import Genesys from '../Contsrc/contexts/Connect

/**
 * The CampaignDialog component is use to tie a campaign with a List Management Profile
 * Expects No params our query string values on the route
 * * @category Pages
 *  @component
 *
 */
export function CampaignDialog(props) {
	const navigate = useNavigate();
	const client = generateClient();
	const userContext = useContext(UserContext);
	const [isActive, setIsActive] = useState(false);
	// const { showDialog } = useConfirmDialog();
	const { enqueueSnackbar } = useSnackbar();
	const [profiles, setProfiles] = useState([]);
	const [confirmProfileNavigate, setConfirmProfileNavigate] = useState(false);
	const [confirmSegmentNavigate, setConfirmSegmentNavigate] = useState(false);
	const [sort, setSort] = useState(false);
	const emptyPendingSegment = {
		segmentId: '',
		segmentName: '',
		segmentRatio: '1',
	};
	const [pendingSegment, setPendingSegment] = useState(emptyPendingSegment);
	const [availableSegments, setAvailableSegments] = useState([]);

	//Nic
	const [availableSkills, setAvailableSkills] = useState([]);

	//Nobelbiz
	const [
		availableNbzCampaigns,
		// setAvailableNbzCampaings
	] = useState([]);
	const [
		availableNbzServices,
		// setAvailableNbzServices
	] = useState([]);
	const [newNobelbizCampaign, setNewNobelbizCampaign] = useState(false);
	const [newNobelbizService, setNewNobelbizService] = useState(false);

	//Genesys
	const [
		genQueues,
		// setGenQueues
	] = useState([]);

	// Webex
	const [webexEntryPoints, setWebexEntryPoints] = useState([]);
	const [webexDialNumbers, setWebexDialNumbers] = useState([]);

	// Amazon Connect
	const [amazonConnectCampaigns, setAmazonConnectCampaigns] = useState([]);

	// RingCX Campaigns
	const [ringCXCampaigns, setRingCXCampaigns] = useState([]);

	//Sorting
	const [sortFields, setSortFields] = useState([]);

	const [campaign, setCampaign] = useState(props.campaign);
	const [loading, setLoading] = useState({
		profiles: false,
		skills: false,
		segments: false,
		formattingData: false,
	});

	const selectedSegments = useRef(new Set());
	const ccis = ['NICE_inContact', 'Test_Harness'];

	const sortOrder = ["Ascending", "Descending"];

	const [expireCampaign, setExpireCampaign] = useState(false);

	useEffect(() => {
		async function getData() {
			setLoading({
				profiles: true,
				skills: true,
				segments: true,
				formattingData: true,
			});
			const promises = [];
			if (userContext.telephonyProvider === 'NiC') {
				promises.push(
					new Promise((resolve, reject) => {
						try {
							resolve(InContact.getSSValidSkills());
						} catch (err) {
							reject(err);
						}
					}),
				);
			} else {
				promises.push(['No InContact Data']);
			}
			promises.push(getSegments());
			promises.push(getProfiles());
			promises.push(getSortFields());
			const results = await Promise.all(promises);
			setLoading({
				profiles: false,
				skills: false,
				segments: false,
				formattingData: true,
			});

			const sortedSkills = results[0].sort((a, b) =>
				a.skillName.localeCompare(b.skillName),
			);
			setAvailableSkills(sortedSkills);

			if (userContext.telephonyProvider === 'Nobelbiz') {
				// const nbzServices = await Nobelbiz.getServices();
				// const nbzCampaigns = await Nobelbiz.getCampaigns();
				// setAvailableNbzServices(nbzServices);
				// setAvailableNbzCampaings(nbzCampaigns);
			}

			if (userContext.telephonyProvider === 'Genesys') {
				// const token = (await fetchAuthSession()).tokens.idToken;
				// const queues = await Genesys.getQueues(token, userContext.apiKey);
				// setGenQueues(queues);
			}

			if (userContext.telephonyProvider === 'Webex') {
				const token = (await fetchAuthSession()).tokens.idToken;
				const entryPoints = await Webex.getEntryPoints(token, userContext.apiKey);
				const dialNumbers = await Webex.getDialNumbers(token, userContext.apiKey);
				console.log('Entry Points : ', entryPoints)
				setWebexEntryPoints(entryPoints);
				setWebexDialNumbers(dialNumbers);
			}

			if (userContext.telephonyProvider === 'RingCX') {
				const campaigns = [];
				const token = (await fetchAuthSession()).tokens.idToken;
				const ringDialGroups = await RingCX.getRingCampaigns(token, userContext.apiKey);
				for (const dialGroup of ringDialGroups) {
					for (const campaign of dialGroup.campaigns) {
						campaigns.push(campaign);
					}
				}
				setRingCXCampaigns(campaigns);
			}

			if (userContext.telephonyProvider === 'AmazonConnect') {
				const token = (await fetchAuthSession()).tokens.idToken;
				const amazonCampaigns = await AmazonConnect.getCampaigns(token, userContext.apiKey);
				console.log('Amazon Campaigns : ', amazonCampaigns);
				setAmazonConnectCampaigns(amazonCampaigns ? amazonCampaigns : []);
			}

			setLoading({
				profiles: false,
				skills: false,
				segments: false,
				formattingData: false,
			});
		}

		if (props.open) {
			getData();
		}
		if (props.campaign) {
			if (userContext.telephonyProvider === 'Webex') {
				console.log('props.campaign : ', props.campaign);
				// props.campaign.cciMetaData = JSON.parse(props.campaign.cciMetaData);
			}
			setCampaign(formatCampaign());
			if (
				props?.campaign.status === 'Active' ||
				props?.campaign.status === 'Paused'
			) {
				setIsActive(true);
			} else {
				setIsActive(false);
			}
		} else {
			setIsActive(false);
			const today = new Date();

			let campaignModel = {
				id: '',
				name: '',
				tenant: '',
				segments: [],
				sort: false,
				skillId: '',
				skillName: '',
				skillSource: '',
				nbzCampaign: '',
				nbzService: '',
				campaignProfileId: '',
				expireDate: new Date('01/01/2099').toISOString(),
				status: 'Pending',
				stopOnCompletion: false,
				cci: 'NICE_inContact',
				static: false,
				autoMode: true,
			};

			if (userContext.telephonyProvider === 'Webex') {
				campaignModel.cciMetaData = {
					wbxDialerType: 'predictive',
					wbxDialerRate: 1.0,
					wbxPullSize: 10,
					wbxOutDialAni: '',
					wbxNoAnswerRingLimit: 32,
					wbxMaxDialingRate: 1.0,
					wbxAbandonRate: 3.0,
					wbxPredictiveCorrection: 70,
					wbxPredictiveGain: 1.0,
					wbxCpaEnabled: true,
					wbxAmdEnabled: true,
					wbxMinSilencePeriod: 608,
					wbxAnalysisPeriod: 1000,
					wbxMinimumValidSpeech: 112,
					wbxMaxTimeAnalysis: 3000,
					wbxMaxTermToneAnalysis: 30000,
					wbxTerminatingToneDetect: false,
					wbxReservationPercentage: 100
				}
			}

			setCampaign(campaignModel);
		}
	}, [props.open]);

	async function getSegments() {
		let allSegments = [];
		let nextToken;
		do {
			const options = {
				tenant: userContext.tenantId,
			};
			if (nextToken) {
				options.nextToken = nextToken;
			}
			const segments = await client.graphql({
				query: segmentByTenant,
				variables: options,
			});
			allSegments = [...allSegments, ...segments.data.segmentByTenant.items];
			nextToken = segments.data.segmentByTenant.nextToken;
		} while (nextToken);

		const sortedSegments = allSegments.sort((a, b) =>
			a.name.localeCompare(b.name),
		);
		setAvailableSegments(sortedSegments);
	}

	async function getProfiles() {
		let allProfiles = [];
		let nextToken;
		do {
			const options = {
				tenant: userContext.tenantId,
			};
			if (nextToken) {
				options.nextToken = nextToken;
			}
			const profiles = await client.graphql({
				query: profileByTenant,
				variables: options,
			});
			allProfiles = [...allProfiles, ...profiles.data.profileByTenant.items];
			nextToken = profiles.data.profileByTenant.nextToken;
		} while (nextToken);

		const sortedProfiles = allProfiles.sort((a, b) =>
			a.name.localeCompare(b.name),
		);
		setProfiles(sortedProfiles);
	}

	async function getSortFields() {
		let viableFields = [{ "displayName": "None", "isPoc": false, "name": "none", "type": "Number" }];
		for (const field of userContext.customFields) {
			if (field.type === 'Number' && !field.isPOC) {
				viableFields.push(field);
			}
		}
		setSortFields(viableFields);
	}

	function formatCampaign() {
		const tempCampaign = JSON.parse(JSON.stringify(props.campaign));
		for (const key of Object.keys(props.campaign)) {
			if (props.campaign[key] != null) {
				tempCampaign[key] = props.campaign[key];
			}
		}

		if (tempCampaign.segments && !Array.isArray(tempCampaign.segments)) {
			tempCampaign.segments = JSON.parse(tempCampaign.segments);
		}

		if (
			userContext.telephonyProvider === 'Nobelbiz' &&
			props.campaign.skillId
		) {
			tempCampaign.nbzCampaign = JSON.parse(props.campaign.skillId);
			tempCampaign.nbzService = props.campaign.skillSource;
		}

		if (userContext.telephonyProvider === 'Webex') {
			tempCampaign.autoMode = true;
			if (typeof tempCampaign.cciMetaData === 'string') {
				tempCampaign.cciMetaData = JSON.parse(tempCampaign.cciMetaData);
			}
		}

		return tempCampaign;
	}

	function blurSegmentsField(formikProps) {
		formikProps.handleBlur({ target: { name: 'segments' } });
	}

	const handleSelectSegment = (event) => {
		const match = availableSegments.find((x) => x.id === event.target.value);
		setPendingSegment({
			...pendingSegment,
			segmentId: event.target.value,
			segmentName: match.name,
		});
	};
	const handleSelectRatio = (event, segment, formikProps) => {
		const updateSegmentIndex = formikProps.values.segments.findIndex(
			(x) => x.segmentId === segment.segmentId,
		);

		formikProps.values.segments[updateSegmentIndex].segmentRatio =
			event.target.value;
		formikProps.setFieldValue('segments', formikProps.values.segments);
	};
	const handleSort = () => {
		setSort(!sort);
	};
	const deleteSegment = (segment, formikProps) => {
		if (!isActive) {
			blurSegmentsField(formikProps);
			selectedSegments.current.delete(segment.segmentId);
			formikProps.setFieldValue(
				'segments',
				formikProps.values.segments.filter(
					(x) => x.segmentId !== segment.segmentId,
				),
			);
		}
	};

	const clearSegments = (formikProps) => {
		blurSegmentsField(formikProps);
		selectedSegments.current.clear();
		formikProps.setFieldValue('segments', []);
	};

	const addSegment = (formikProps) => {
		if (!isActive) {
			blurSegmentsField(formikProps);
			selectedSegments.current.add(pendingSegment.segmentId);
			formikProps.setFieldValue('segments', [
				...formikProps.values.segments,
				pendingSegment,
			]);
			setPendingSegment(emptyPendingSegment);
		}
	};

	async function handleClose() {
		// dirty
		// if (
		// 	(!dirty && props.operation === 'edit') ||
		// 	(await showDialog({
		// 		children:
		// 			'You have unsaved changes. Are you sure you want to leave this page?',
		// 		confirmTxt: 'Yes',
		// 		cancelTxt: 'Cancel',
		// 	})) === DialogResult.Confirm
		// ) {
		props.setOpen(false);
		setPendingSegment(emptyPendingSegment);
		setNewNobelbizCampaign(false);
		setNewNobelbizService(false);
		// }
	}

	const handleSelectSortField = (event, segment, formikProps) => {
		const updateSegmentIndex = _.findIndex(formikProps.values.segments, ['segmentId', segment.segmentId]);
		formikProps.values.segments[updateSegmentIndex].sortField = event.target.value;
		formikProps.setFieldValue('segments', formikProps.values.segments);
	}

	const handleSelectSortOrder = (event, segment, formikProps) => {
		const updateSegmentIndex = _.findIndex(formikProps.values.segments, ['segmentId', segment.segmentId]);
		formikProps.values.segments[updateSegmentIndex].sortOrder = event.target.value;
		formikProps.setFieldValue('segments', formikProps.values.segments);
	}

	return (
		<Box>
			<Dialog open={props.open} maxWidth='lg' >
				<Backdrop open={loading.formattingData} style={{ zIndex: 2 }}>
					<CircularProgress variant="indeterminate" size={50} color="primary" />
				</Backdrop>
				<DialogTitle id="dp-dialog-title">{campaign?.id ? 'Edit Campaign' : 'New Campaign'}</DialogTitle>
				<DialogContent>
					<Formik
						initialValues={campaign}
						enableReinitialize
						validationSchema={object({
							name: string().required('A Campaign name is required'),
							skillId:
								userContext.telephonyProvider === 'NiC' &&
								mixed().required('A skill is required'),
							campaignProfileId: string().required('A profile is required'),
							cci:
								userContext.telephonyProvider === 'NiC' &&
								string().required('Please choose a CCI'),
							segments: array()
								.min(1, 'At least one segment is required.')
								.max(8, 'Campaigns cannot have more than 8 segments.'),
							nbzCampaign:
								userContext.telephonyProvider === 'Nobelbiz' &&
								!newNobelbizCampaign &&
								string().required('A Nobelbiz campaign is required'),
							newNbzCampaignName:
								userContext.telephonyProvider === 'Nobelbiz' &&
								newNobelbizCampaign &&
								string().required(
									'Please enter a name for new Nobelbiz Campaign',
								),
							newNbzCampaignCID:
								userContext.telephonyProvider === 'Nobelbiz' &&
								newNobelbizCampaign &&
								string().required(
									'Please enter a caller id for new Nobelbiz Campaign',
								),
							newNbzCampaignControlDevice:
								userContext.telephonyProvider === 'Nobelbiz' &&
								newNobelbizCampaign &&
								string().required(
									'Please enter a control device for new Nobelbiz Campaign',
								),
							nbzService:
								userContext.telephonyProvider === 'Nobelbiz' &&
								!newNobelbizService &&
								string().required('A Nobelbiz service is required'),
							newNbzService:
								userContext.telephonyProvider === 'Nobelbiz' &&
								newNobelbizService &&
								string().required(
									'Please enter a name for the new Nobelbiz Service',
								),
						})}
						onSubmit={async (formikValues) => {
							try {
								const values = JSON.parse(JSON.stringify(formikValues));
								delete values.profile;
								delete values.createdAt;
								delete values.updatedAt;
								delete values.tableData;
								let successMsg = 'Campaign Created';
								let mutation = createCampaign;
								if (values && values.id && values.id !== '') {
									mutation = updateCampaign;
									successMsg = 'Campaign Updated';
								} else {
									delete values.id;
								}

								values.tenant = userContext.tenantId;
								values.segments = JSON.stringify(values.segments);

								if (expireCampaign) {
									values.expireDate = new Date(values.expireDate).toISOString();
								} else {
									// Set date way in the future
									values.expireDate = new Date('01/01/2099').toISOString();
								}

								try {
									if (userContext.telephonyProvider === 'NiC') {
										const skillInfo = availableSkills.find(
											(x) => +x.skillId === +values.skillId,
										);
										values.skillName = skillInfo.skillName;
										values.skillSource = skillInfo.source;
									} else if (userContext.telephonyProvider === "Webex") {
										// Putting == in case the entry point id's are not typed the same
										values.skillName = webexEntryPoints.find(x => x.id == values.skillId).name;
										values.cci = userContext.telephonyProvider;
									} else if (userContext.telephonyProvider === 'RingCX') {
										// Putting == to handle mismatch in id types
										values.skillName = ringCXCampaigns.find(x => x.campaignId == values.skillId).campaignName;
										values.cci = userContext.telephonyProvider;
									} else if (userContext.telephonyProvider === 'AmazonConnect') {
										// Putting == to handle mismatch in id types
										values.skillName = amazonConnectCampaigns.find(x => x.id == values.skillId).name;
										values.cci = userContext.telephonyProvider;
									} else {
										values.skillName = 'none';
										values.cci = userContext.telephonyProvider;
									}
									delete values.createdAt;
									delete values.updatedAt;
									delete values.nbzCampaign;
									delete values.nbzService;
									values.cciMetaData = JSON.stringify(values.cciMetaData);
									await client.graphql({
										query: mutation,
										variables: { input: values },
									});

									enqueueSnackbar(successMsg);
									props.setOpen(false);
									selectedSegments.current.clear();
									setNewNobelbizCampaign(false);
									setNewNobelbizService(false);
								} catch (err) {
									console.error(err);
								}

							} catch (error) {
								console.error(error);
							}

						}}
					>
						{(formikProps) => (
							<form onSubmit={formikProps.handleSubmit}>
								<Card
									style={cardStyle}
									elevation={0}
								>
									<CardHeader title="Campaign Configuration" titleTypographyProps={
										{ variant: 'overline' }

									}

										sx={{
											padding: '0px 16px'
										}} />
									<CardContent
										sx={{
											padding: '16px'
										}}
									>
										<Box display="grid"
											gridTemplateColumns="1fr 1fr 1fr 1fr"
											gap="20px" alignItems='center'>
											<Box>
												<TextField
													color="primary"
													margin="dense"
													name="name"
													label="Name"
													type="text"
													required
													disabled={isActive}
													value={formikProps.values.name}
													onChange={formikProps.handleChange}
													onBlur={formikProps.handleBlur}
													error={formikProps.errors.name && formikProps.touched.name}
													helperText={formikProps.touched.name && formikProps.errors.name} />
											</Box>
											<Box>
												<FormControl sx={selectInputStyle} required disabled={loading.profiles} error={formikProps.errors.campaignProfileId && formikProps.touched.campaignProfileId}>
													<InputLabel id="profile-label">Profile</InputLabel>
													<Select

														color="primary"
														margin="dense"
														name="campaignProfileId"
														label="Profile"
														disabled={isActive}
														onChange={(e) => {
															if (e.target.value === 'new') {
																setConfirmProfileNavigate(true);
															} else {
																console.log('e : ', e);
																formikProps.handleChange(e);
															}
														}}
														onBlur={formikProps.handleBlur}
														value={formikProps.values.campaignProfileId}>
														{profiles.map(profile =>
															<MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>
														)}
														{profiles?.length > 0 && <Divider />}
														<MenuItem color='primary' value={'new'}>Create New Profile</MenuItem>
													</Select>
													{formikProps.touched.campaignProfileId && <FormHelperText>{formikProps.errors.campaignProfileId}</FormHelperText>}
												</FormControl>
											</Box>
											<Box>
												<FormControlLabel
													label={
														<Box display='flex' alignItems='center' gap='.5em'>
															<Tooltip title="Any changes to your contact inventory will not affect this campaign's inventory.">
																<InfoOutlined color="primary" />
															</Tooltip>
															<Typography>Static Campaign</Typography>
														</Box>
													}
													control={<Switch
														color="primary"
														name="static"
														disabled={isActive}
														checked={formikProps.values.static}
														onBlur={formikProps.handleBlur}
														onChange={() => {
															formikProps.setFieldValue('static', !formikProps.values.static);
														}}
													/>}
												/>
											</Box>
											{/* This is a little confusing but the approver mode should be true if auto mode is false */}
											{userContext.telephonyProvider !== "Webex" && <Box>
												<FormControlLabel
													label={
														<Box display='flex' alignItems='center' gap='.5em'>
															<Tooltip title="Calls are approved manually before being placed.">
																<InfoOutlined color="primary" />
															</Tooltip>
															<Typography>Approver Mode</Typography>
														</Box>
													}
													control={<Switch
														color="primary"
														name="autoMode"
														disabled={isActive}
														checked={formikProps.values.autoMode ? false : true}
														onBlur={formikProps.handleBlur}
														onChange={() => {
															formikProps.setFieldValue('autoMode', !formikProps.values.autoMode);
														}}
													/>}
												/>
											</Box>}
											{/* <Box>
											<FormControlLabel
												label="Stop at exhaustion"
												control={<Switch
													color="primary"
													name="stopOnCompletion"
													disabled={isActive}
													checked={formikProps.values.stopOnCompletion}
													onBlur={formikProps.handleBlur}
													onChange={formikProps.handleChange}
												/>}
											/>
										</Box> */}
											{/* <Box>
											<FormControlLabel
												label="Expire Campaign"
												control={
													<Switch
														color="primary"
														name="expireCampaign"
														disabled={isActive}
														checked={expireCampaign}
														onChange={() => {
															setExpireCampaign(!expireCampaign);
														}}
													/>
												}
											/>
										</Box> */}
											{/* {expireCampaign && <Box>
											<TextField
												sx={selectInputStyle}
												required
												color="primary"
												margin="dense"
												name="expireDate"
												label="Expiration Date"
												type="datetime-local"
												disabled={isActive}
												value={new Date(formikProps.values.expireDate).toISOString().slice(0, 16)}
												onChange={formikProps.handleChange}
												onBlur={formikProps.handleBlur}
												error={formikProps.errors.expireDate && formikProps.touched.expireDate}
												InputLabelProps={{
													shrink: true
												}} />
										</Box>} */}
										</Box>
									</CardContent>
								</Card>
								<Card
									style={cardStyle}
									elevation={0}
								>
									<CardHeader
										sx={{
											padding: '0px 16px'
										}}
										title="Contact Center" titleTypographyProps={
											{ variant: 'overline' }
										} />
									<CardContent>
										<Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={2} alignItems='center'>
											{userContext.telephonyProvider === "NiC" &&
												<FormControl sx={selectInputStyle} required error={formikProps.errors.skillId && formikProps.touched.skillId} disabled={loading.skills}>
													<InputLabel id="skill-label">Skill</InputLabel>
													<Select
														color="primary"
														margin="dense"
														name="skillId"
														label="Skill"
														disabled={isActive}
														onChange={formikProps.handleChange}
														onBlur={formikProps.handleBlur}
														value={formikProps.values.skillId}>
														{availableSkills.map(skill =>
															<MenuItem key={skill.skillId} value={skill.skillId}>{skill.source}-{skill.skillName}</MenuItem>
														)}
													</Select>
													{formikProps.touched.skillId && <FormHelperText>{formikProps.errors.skillId}</FormHelperText>}
												</FormControl>
											}
											{userContext.telephonyProvider === 'Genesys' && isVoiceProfileSelected(formikProps.values.campaignProfileId) &&
												<FormControl required error={formikProps.errors.skillId && formikProps.touched.skillId} disabled={loading.skills}>
													<InputLabel id="skill-label">Queue</InputLabel>
													<Select
														color="primary"
														margin="dense"
														name="skillId"
														label="Queue"
														disabled={isActive}
														onChange={formikProps.handleChange}
														onBlur={formikProps.handleBlur}
														value={formikProps.values.skillId}>
														{genQueues.map(queue =>
															<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
														)}
													</Select>
													{formikProps.touched.skillId && <FormHelperText>{formikProps.errors.skillId}</FormHelperText>}
												</FormControl>
											}
											{userContext.telephonyProvider === 'Webex' &&
												<>
													<FormControl required error={formikProps.errors.skillId && formikProps.touched.skillId} disabled={loading.skills}>
														<InputLabel id="skill-label">Entry Point</InputLabel>
														<Select
															color="primary"
															margin="dense"
															name="skillId"
															label="Entry Point"
															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															value={formikProps.values.skillId}>
															{webexEntryPoints?.map(ep =>
																<MenuItem key={ep.id} value={ep.id}>{ep.name}</MenuItem>
															)}
														</Select>
														{formikProps.touched.skillId && <FormHelperText>{formikProps.errors.skillId}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<InputLabel id="outDialNum">Outdial number</InputLabel>
														<Select
															id="wbxOutDialAni"
															color="primary"
															margin="dense"
															name="cciMetaData.wbxOutDialAni"
															label="Outdial Number"

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxOutDialAni && formikProps.touched?.cciMetaData?.wbxOutDialAni}
															helperText={formikProps.touched?.cciMetaData?.wbxOutDialAni && formikProps.errors?.cciMetaData?.wbxOutDialAni}
															value={formikProps.values?.cciMetaData?.wbxOutDialAni}>
															{webexDialNumbers?.map((dn) => (<MenuItem value={dn}>{dn}</MenuItem>))}
														</Select>
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxPullSize"
															label="Pull Count"
															type="number"

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxPullSize && formikProps.touched?.cciMetaData?.wbxPullSize}
															helperText={formikProps.touched?.cciMetaData?.wbxPullSize && formikProps.errors?.cciMetaData?.wbxPullSize}
															value={formikProps.values?.cciMetaData?.wbxPullSize} />
														{formikProps.touched?.cciMetaData?.wbxPullSize && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxPullSize}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<InputLabel id="dialerType">Dialer Type</InputLabel>
														<Select
															id="wbxDialerType"
															color="primary"
															margin="dense"
															name="cciMetaData.wbxDialerType"
															label="Dialing Type"
															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxDialerType && formikProps.touched?.cciMetaData?.wbxDialerType}
															helperText={formikProps.touched?.cciMetaData?.wbxDialerType && formikProps.errors?.cciMetaData?.wbxDialerType}
															value={formikProps.values?.cciMetaData?.wbxDialerType}>
															<MenuItem key="wbxPredictive" value="predictive">Predictive</MenuItem>
															<MenuItem key="wbxProgressive" value="progressive">Progressive</MenuItem>

															<MenuItem key="wbxPredictiveIVR" value="predictive_ivr">Predictive IVR</MenuItem>
															<MenuItem key="wbxProgressiveIVR" value="progressive_ivr">Progressive IVR</MenuItem>
														</Select>
														{formikProps.touched?.cciMetaData?.wbxDialerType && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxDialerType}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxDialerRate"
															label="Dialing Rate"
															type="number"

															disabled={isActive}
															min={1.0}
															max={10.0}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxDialerRate && formikProps.touched?.cciMetaData?.wbxDialerRate}
															helperText={formikProps.touched?.cciMetaData?.wbxDialerRate && formikProps.errors?.cciMetaData?.wbxDialerRate}
															value={formikProps.values?.cciMetaData?.wbxDialerRate} />
														{formikProps.touched?.cciMetaData?.wbxDialerRate && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxDialerRate}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxMaxDialingRate"
															label="Max Dialing Rate"
															type="number"
															min={1.0}
															max={10.0}

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxMaxDialingRate && formikProps.touched?.cciMetaData?.wbxMaxDialingRate}
															helperText={formikProps.touched?.cciMetaData?.wbxMaxDialingRate && formikProps.errors?.cciMetaData?.wbxMaxDialingRate}
															value={formikProps.values?.cciMetaData?.wbxMaxDialingRate} />
														{formikProps.touched?.cciMetaData?.wbxMaxDialingRate && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxMaxDialingRate}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxPredictiveGain"
															label="Predictive Gain"
															type="number"

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxPredictiveGain && formikProps.touched?.cciMetaData?.wbxPredictiveGain}
															helperText={formikProps.touched?.cciMetaData?.wbxPredictiveGain && formikProps.errors?.cciMetaData?.wbxPredictiveGain}
															value={formikProps.values?.cciMetaData?.wbxPredictiveGain} />
														{formikProps.touched?.cciMetaData?.wbxPredictiveGain && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxPredictiveGain}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxPredictiveCorrection"
															label="Predictive Correction"
															type="number"

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxPredictiveCorrection && formikProps.touched?.cciMetaData?.wbxPredictiveCorrection}
															helperText={formikProps.touched?.cciMetaData?.wbxPredictiveCorrection && formikProps.errors?.cciMetaData?.wbxPredictiveCorrection}
															value={formikProps.values?.cciMetaData?.wbxPredictiveCorrection} />
														{formikProps.touched?.cciMetaData?.wbxPredictiveCorrection && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxPredictiveCorrection}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxAbandonRate"
															label="Max Abandon Rate"
															type="number"

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxAbandonRate && formikProps.touched?.cciMetaData?.wbxAbandonRate}
															helperText={formikProps.touched?.cciMetaData?.wbxAbandonRate && formikProps.errors?.cciMetaData?.wbxAbandonRate}
															value={formikProps.values?.cciMetaData?.wbxAbandonRate} />
														{formikProps.touched?.cciMetaData?.wbxAbandonRate && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxAbandonRate}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<FormControlLabel
															control={
																<Switch
																	color="primary"
																	name="cciMetaData.wbxAmdEnabled"
																	disabled={isActive}
																	onChange={() => {
																		formikProps.setFieldValue('cciMetaData.wbxAmdEnabled', !formikProps.values.cciMetaData.wbxAmdEnabled);
																	}}
																	onBlur={formikProps.handleBlur}
																	error={formikProps.errors?.cciMetaData?.wbxAmdEnabled && formikProps.touched?.cciMetaData?.wbxAmdEnabled}
																	helperText={formikProps.touched?.cciMetaData?.wbxAmdEnabled && formikProps.errors?.cciMetaData?.wbxAmdEnabled}
																	checked={formikProps.values?.cciMetaData?.wbxAmdEnabled} />
															}
															label="AMD Enabled" />
														{formikProps.touched?.cciMetaData?.wbxAmdEnabled && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxAmdEnabled}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxNoAnswerRingLimit"
															label="No Answer Ring Limit"
															type="number"

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxNoAnswerRingLimit && formikProps.touched?.cciMetaData?.wbxNoAnswerRingLimit}
															helperText={formikProps.touched?.cciMetaData?.wbxNoAnswerRingLimit && formikProps.errors?.cciMetaData?.wbxNoAnswerRingLimit}
															value={formikProps.values?.cciMetaData?.wbxNoAnswerRingLimit} />
														{formikProps.touched?.cciMetaData?.wbxNoAnswerRingLimit && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxNoAnswerRingLimit}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxMinSilencePeriod"
															label="Minimum Silence Period"
															type="number"

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxMinSilencePeriod && formikProps.touched?.cciMetaData?.wbxMinSilencePeriod}
															helperText={formikProps?.cciMetaData?.touched.wbxMinSilencePeriod && formikProps.errors?.cciMetaData?.wbxMinSilencePeriod}
															value={formikProps.values?.cciMetaData?.wbxMinSilencePeriod} />
														{formikProps.touched?.cciMetaData?.wbxMinSilencePeriod && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxMinSilencePeriod}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxAnalysisPeriod"
															label="Analysis Period"
															type="number"

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxAnalysisPeriod && formikProps.touched?.cciMetaData?.wbxAnalysisPeriod}
															helperText={formikProps.touched?.cciMetaData?.wbxAnalysisPeriod && formikProps.errors?.cciMetaData?.wbxAnalysisPeriod}
															value={formikProps.values?.cciMetaData?.wbxAnalysisPeriod} />
														{formikProps.touched?.cciMetaData?.wbxAnalysisPeriod && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxAnalysisPeriod}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxMinimumValidSpeech"
															label="Minimum Valid Speech"
															type="number"

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxMinimumValidSpeech && formikProps.touched?.cciMetaData?.wbxMinimumValidSpeech}
															helperText={formikProps.touched?.cciMetaData?.wbxMinimumValidSpeech && formikProps.errors?.cciMetaData?.wbxMinimumValidSpeech}
															value={formikProps.values?.cciMetaData?.wbxMinimumValidSpeech} />
														{formikProps.touched?.cciMetaData?.wbxMinimumValidSpeech && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxMinimumValidSpeech}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxMaxTimeAnalysis"
															label="Max Time Analysis"
															type="number"

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxMaxTimeAnalysis && formikProps.touched?.cciMetaData?.wbxMaxTimeAnalysis}
															helperText={formikProps.touched?.cciMetaData?.wbxMaxTimeAnalysis && formikProps.errors?.cciMetaData?.wbxMaxTimeAnalysis}
															value={formikProps.values?.cciMetaData?.wbxMaxTimeAnalysis} />
														{formikProps.touched?.cciMetaData?.wbxMaxTimeAnalysis && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxMaxTimeAnalysis}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<TextField
															color="primary"
															margin="dense"
															name="cciMetaData.wbxMaxTermToneAnalysis"
															label="Max Term Tone Analysis"
															type="number"

															disabled={isActive}
															onChange={formikProps.handleChange}
															onBlur={formikProps.handleBlur}
															error={formikProps.errors?.cciMetaData?.wbxMaxTermToneAnalysis && formikProps.touched?.cciMetaData?.wbxMaxTermToneAnalysis}
															helperText={formikProps.touched?.cciMetaData?.wbxMaxTermToneAnalysis && formikProps.errors?.cciMetaData?.wbxMaxTermToneAnalysis}
															value={formikProps.values?.cciMetaData?.wbxMaxTermToneAnalysis} />
														{formikProps.touched?.cciMetaData?.wbxMaxTermToneAnalysis && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxMaxTermToneAnalysis}</FormHelperText>}
													</FormControl>

													<FormControl required >
														<FormControlLabel
															control={
																<Switch
																	color="primary"
																	name="cciMetaData.wbxTerminatingToneDetect"
																	disabled={isActive}
																	onChange={() => {
																		formikProps.setFieldValue('cciMetaData.wbxTerminatingToneDetect', !formikProps.values.cciMetaData.wbxTerminatingToneDetect);
																	}}
																	onBlur={formikProps.handleBlur}
																	error={formikProps.errors?.cciMetaData?.wbxTerminatingToneDetect && formikProps.touched?.cciMetaData?.wbxTerminatingToneDetect}
																	helperText={formikProps.touched?.cciMetaData?.wbxTerminatingToneDetect && formikProps.errors?.cciMetaData?.wbxTerminatingToneDetect}
																	checked={formikProps.values?.cciMetaData?.wbxTerminatingToneDetect} />
															}
															label="Terminating Tone Detect" />
														{formikProps.touched?.cciMetaData?.wbxTerminatingToneDetect && <FormHelperText>{formikProps.errors?.cciMetaData?.wbxTerminatingToneDetect}</FormHelperText>}
													</FormControl>
												</>
											}
											{
												userContext.telephonyProvider === 'AmazonConnect' &&
												<FormControl required error={formikProps.errors.skillId && formikProps.touched.skillId} disabled={loading.skills}>
													<InputLabel id="skill-label">Queue</InputLabel>
													<Select
														color="primary"
														margin="dense"
														name="skillId"
														label="Amazon Connect Campaign"
														disabled={isActive}
														onChange={formikProps.handleChange}
														onBlur={formikProps.handleBlur}
														value={formikProps.values.skillId}>
														{amazonConnectCampaigns.map(campaign =>
															<MenuItem key={campaign.id} value={campaign.id}>{campaign.name}</MenuItem>
														)}
													</Select>
													{formikProps.touched.skillId && <FormHelperText>{formikProps.errors.skillId}</FormHelperText>}
												</FormControl>
											}
											{
												userContext.telephonyProvider === 'RingCX' &&
												<FormControl required error={formikProps.errors.skillId && formikProps.touched.skillId} disabled={loading.skills}>
													<InputLabel id="skill-label">RingCX Campaign</InputLabel>
													<Select
														color="primary"
														margin="dense"
														name="skillId"
														label="RingCX Campaign"
														disabled={isActive}
														onChange={formikProps.handleChange}
														onBlur={formikProps.handleBlur}
														value={formikProps.values.skillId}>
														{ringCXCampaigns.map(campaign =>
															<MenuItem key={campaign.campaignId} value={campaign.campaignId}>{campaign.campaignName}</MenuItem>
														)}
													</Select>
													{formikProps.touched.skillId && <FormHelperText>{formikProps.errors.skillId}</FormHelperText>}
												</FormControl>
											}

										</Box >
									</CardContent >
								</Card >
								<Card
									style={cardStyle}
									elevation={0}
								>
									<CardHeader
										action={
											<Box display='flex' alignItems='center'>
												<Box>
													<FormControl sx={selectInputStyle} disabled={loading.segments} >
														<InputLabel color="primary" id="campaign-label">Segment</InputLabel>
														<Select
															color="primary"
															margin="dense"
															name="segmentId"
															label="Add Segment"
															disabled={isActive}
															value={pendingSegment.segmentId}
															onChange={
																(e) => {
																	if (e.target.value === 'new') {
																		setConfirmSegmentNavigate(true);
																	} else {
																		handleSelectSegment(e);
																	}
																}}>
															{availableSegments.map(segment =>
																!selectedSegments.current.has(segment.id) &&
																!formikProps.values.segments.some(x => x.segmentId === segment.id) &&
																<MenuItem key={segment.id} value={segment.id}>{segment.name}</MenuItem>
															)}
															{availableSegments.length > 0 && <Divider />}
															<MenuItem value='new'>Create New Segment</MenuItem>
														</Select>
													</FormControl>
												</Box>
												<Box>
													<Tooltip title={formikProps.values.segments.length >= 8 ? "A campaign cannot have more than 8 segments." : pendingSegment.segmentId === "" ? 'Select a segment' : pendingSegment.segmentRatio > 0 ? "Add Segment" : "Segment Ratio Is Invalid"}>
														<div>
															<IconButton aria-label="add" onClick={() => addSegment(formikProps)} disabled={pendingSegment.segmentId === "" || !(pendingSegment.segmentRatio > 0) || formikProps.values.segments.length >= 8 || isActive}>
																<AddCircleOutlineOutlined color={pendingSegment.segmentId === "" || !(pendingSegment.segmentRatio > 0) || formikProps.values.segments.length >= 8 ? "disabled" : "primary"} fontSize="small" />
															</IconButton>
														</div>
													</Tooltip>
												</Box>

											</Box>
										}
										sx={{
											padding: '0px 16px'
										}}
										title="Segments" titleTypographyProps={
											{ variant: 'overline' }
										} />
									<CardContent
										sx={{
											padding: '16px'
										}}
									>
										<Box>
											<Box>
												<Paper variant='outlined' elevation={0}>
													<Table sx={{ minWidth: 650 }} size="small">
														<TableHead>
															<TableRow>
																<TableCell>Segment Name</TableCell>
																<TableCell align="center">Ratio Percentage</TableCell>
																<TableCell align="center">Ratio</TableCell>
																<TableCell align="center">Sort Field</TableCell>
																<TableCell align="center">Sort Direction</TableCell>
																<TableCell align="center">Remove</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{formikProps.values.segments.map(segment => {
																return (
																	<TableRow key={segment.segmentId}>
																		<TableCell>{segment.segmentName}</TableCell>
																		<TableCell align="center">{`${segment.segmentRatio} in ${_.sumBy(formikProps.values.segments, (item) => { return Number(item.segmentRatio) })}`}</TableCell>
																		<TableCell align="center">
																			<TextField
																				color="primary"
																				margin="dense"
																				name="segmentRatio"
																				type="number"
																				InputProps={{
																					inputProps: {
																						max: 100, min: 0, defaultValue: 1
																					}
																				}}
																				disabled={isActive}
																				value={segment.segmentRatio}
																				onChange={(e) => handleSelectRatio(e, segment, formikProps)}
																			/>
																		</TableCell>
																		<TableCell>
																			<FormControl disabled={loading.segments}>
																				<InputLabel color="primary" id="sort-label">Sort Field</InputLabel>
																				<Select
																					color="primary"
																					margin="dense"
																					sx={{ minWidth: '200px' }}
																					name="sortField"
																					label="Sort Field"
																					disabled={isActive}
																					value={segment.sortField ? segment.sortField : 'none'}
																					onChange={e => handleSelectSortField(e, segment, formikProps,)} >
																					{sortFields.map(sort =>
																						<MenuItem key={sort.name} value={sort.name}>{sort.displayName}</MenuItem>
																					)}
																				</Select>
																			</FormControl>
																		</TableCell>
																		<TableCell>
																			<FormControl disabled={loading.segments}>
																				<InputLabel color="primary" id="campaign-label">Sort Direction</InputLabel>
																				<Select
																					disabled={segment.sortField === 'none' || isActive}
																					color="primary"
																					margin="dense"
																					sx={{ minWidth: '200px' }}
																					name="orderField"
																					label="Sort Direction"
																					value={segment.SortField}
																					defaultValue={segment.sortOrder ? segment.sortOrder : 'Ascending'}
																					onChange={e => handleSelectSortOrder(e, segment, formikProps)} >
																					{sortOrder.map(sortOrder =>
																						<MenuItem key={sortOrder} value={sortOrder}>{sortOrder}</MenuItem>
																					)}
																				</Select>
																			</FormControl>
																		</TableCell>
																		<TableCell align="center">
																			<Tooltip title="Delete Segment">
																				<IconButton aria-label="delete" onClick={() => deleteSegment(segment, formikProps)} disabled={isActive}>
																					<DeleteOutlined color={isActive ? "disabled" : "primary"} fontSize="small" />
																				</IconButton>
																			</Tooltip>
																		</TableCell>
																	</TableRow>
																)
															})}
														</TableBody>
													</Table>
												</Paper>
											</Box>
										</Box>
									</CardContent>
								</Card>
								<DialogActions>
									<Button sx={actionTwoButtonStyle} onClick={() => handleClose(formikProps.dirty)}>
										Cancel
									</Button>

									<Button type='submit' sx={actionOneButtonStyle}>
										Save
									</Button>

								</DialogActions>
							</form >
						)}
					</Formik >
				</DialogContent >
			</Dialog >
			<ConfirmDialog
				open={confirmProfileNavigate}
				title="Create New Profile"
				description="You are about to navigate away from this page. Any unsaved changes will be lost. Are you sure you want to continue?"
				actionOneText="Confirm"
				actionOneHandler={() => {
					setConfirmProfileNavigate(false);
					navigate('/profiles/new');
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => setConfirmProfileNavigate(false)}
			/>
			<ConfirmDialog
				open={confirmSegmentNavigate}
				title="Create New Segment"
				description="You are about to navigate away from this page. Any unsaved changes will be lost. Are you sure you want to continue?"
				actionOneText="Confirm"
				actionOneHandler={() => {
					setConfirmSegmentNavigate(false);
					navigate('/segments/new');
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => setConfirmSegmentNavigate(false)}
			/>
		</Box >
	);
}
