import React, { useContext, useEffect, useRef, useState } from 'react';
import { Grid, Typography, IconButton, Tooltip, Box } from '@mui/material';
import { ExpandMore, ExpandLess, Clear } from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { UserContext } from '../../contexts/UserContext';
import ActivityLogTypeFilter from './activityLogTypeFilter';
import { generateClient } from 'aws-amplify/api';
import { esQuery } from 'src/graphql/queries';

const columns = [
	{
		title: 'Timestamp',
		field: 'timestamp',
		render: rowData => {
			return moment(rowData.timestamp).format('LLL')
		},
		defaultSort: 'desc',
		sortField: 'timestamp'
	},
	{ title: 'POC', field: 'pocActual', sortField: 'type.pocActual' },
	{ title: 'Type', field: 'type', sortField: 'type.keyword' },
	{ title: 'Disposition', field: 'disposition', sortField: 'disposition.keyword' },
	{ title: 'Title', field: 'title', sortField: 'title.keyword' },
	{ title: 'Agent', field: 'agent', sortField: 'agent.keyword' }
];

export function ActivityLog(props) {
	const client = generateClient();
	const { contactId } = props;
	const userContext = useContext(UserContext);
	const [data, setData] = useState([]);
	const [selectedTypes, setSelectedTypes] = useState(["Dial"]);
	const [loading, setLoading] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(0);
	const [sort, setSort] = useState({ index: 0, direction: 'desc' });
	const nextTokens = useRef([]);

	useEffect(() => {
		if (contactId) {
			nextTokens.current.splice(0);

			if (page === 0) {
				getData();
			} else {
				setPage(0);
			}

		}
	}, [contactId, selectedTypes, rowsPerPage, sort]);

	useEffect(() => {
		getData();
	}, [page]);

	async function getData() {
		setLoading(true);

		const options = {
			model: 'activitylog',
			action: '_search',
			query: {
				"query": {
					"bool": {
						"must": [
							{
								"term": {
									"tenant.keyword": userContext.tenantId
								}
							},
							{
								"term": {
									"activityLogContactId.keyword": contactId
								}
							}
						]
					}
				}
			}
		};

		if (page - 1 >= 0) {
			options.query.search_after = [nextTokens.current[page - 1]];
		}

		if (sort.index === -1) {
			options.query.sort = [
				"id.keyword"
			];
		} else {
			options.query.sort = [
				{
					[columns[sort.index].sortField]: {
						"order": sort.direction
					}
				}
			];
		}

		if (selectedTypes.length > 0) {
			options.query.query.bool.must.push({
				bool: {
					should: selectedTypes.map(x => {
						return {
							term: {
								"type.keyword": x
							}
						}
					})
				}
			});
		}

		options.query = JSON.stringify(options.query);

		const result = await client.graphql({
			query: esQuery,
			variables: options,
		});

		const parsed = JSON.parse(result.data.esQuery);
		setData(parsed?.hits?.hits?.map(x => x._source) ?? []);
		setTotal(parsed?.hits?.total ?? 0);
		if (parsed?.hits?.hits?.length > 0) {
			nextTokens.current.push(parsed?.hits?.hits[parsed?.hits?.hits?.length - 1].sort[0]);
		}

		setLoading(false);
	}

	function handleSortChange(index, direction) {
		if (sort.index > -1) {
			delete columns[sort.index].defaultSort;
		}

		sort.index = index;

		if (index >= 0) {
			sort.direction = direction;

			columns[index].defaultSort = direction;
		}

		setSort({ ...sort });
	}

	function handlePageChange(newPage) {
		if (newPage < page) nextTokens.current.pop();

		setPage(newPage);
	}

	return (
		<Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
			<MaterialTable
				columns={columns}
				key={data.length}
				data={data}
				isLoading={loading}
				title={
					<Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
							<ActivityLogTypeFilter value={selectedTypes} onChange={e => setSelectedTypes(e.target.value)} />
							<Tooltip title='Clear filter'>
								<IconButton onClick={() => setSelectedTypes([])}>
									<Clear color='primary' />
								</IconButton>
							</Tooltip>
						</Box>
				}
				options={{
					actionsColumnIndex: -1,
					pageSize: rowsPerPage,
					emptyRowsWhenPaging: false,
					debounceInterval: 500,
					search: false,
					headerStyle: {
						borderTop: '1px solid #e0e0e0',
						padding: '16px',
					},
				}}
				detailPanel={[
					rowData => ({
						icon: () => <ExpandMore />,
						openIcon: ExpandLess,
						tooltip: 'Show details',
						render: () => {
							if (typeof rowData.metaData === 'object' || !rowData.metaData) {
								try {
									rowData.metaData = JSON.stringify(rowData.metaData ?? "");
								} catch (err) {
									console.error(err);
									rowData.metaData = JSON.stringify("Could not parse metadata");
								}
							}
							return (
								<Grid>
									{rowData.type === 'Dial' && <ul><li>Disposition Description: {JSON.stringify(JSON.parse(rowData.metaData)?.dispDescription)}</li>
										<li>Disposition Classification Name: {JSON.stringify(JSON.parse(rowData.metaData)?.dispClassName)}</li>
										<li>Campaign Id: {JSON.stringify(JSON.parse(rowData.metaData)?.campaignId)}</li>
										<li>Segment Id: {JSON.stringify(JSON.parse(rowData.metaData)?.segmentId)}</li>
									</ul>}
									{rowData.type === 'Note' && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rowData.body) }} />}
									{rowData.type === 'System' && <Typography variant="body1">{rowData.body}</Typography>}
								</Grid>
							);
						}
					})
				]}
				components={{
					// Toolbar: toolBar,
					Container: (props) => <Box {...props} elevation={0} />,

				}}
			/>
		</Grid>
	);
}
