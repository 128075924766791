import { FormControlLabel, Grid, TextField, Switch, Checkbox, Paper, Chip, Typography, Box } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { DoneOutline } from "@mui/icons-material";

export function C2AOptOut(props) {
	const { formikProps } = props;
	const userContext = useContext(UserContext);

	function handleListItemClick(value, pathString) {
		const index = (formikProps.values?.c2a?.[pathString]?.fields ?? []).indexOf(value);
		const newFieldList = [...(formikProps.values.c2a?.[pathString]?.fields ?? [])];
		if (index === -1) {
			newFieldList.push(value);
		} else {
			newFieldList.splice(index, 1);
		}
		formikProps.setFieldValue(`c2a.${pathString}.fields`, newFieldList);
	}

	return (
		<Box sx={{ width: '100%' }}>
			<Box display="flex" sx={{ width: '100%' }} alignItems="center" justifyContent='flex-start' flexDirection="column" gap={2}>
				{(formikProps.values.c2a?.optOutEnabled ?? false) &&
					<>
							<FormControlLabel
								control={<Checkbox
									checked={formikProps.values.c2a?.optOut?.finalize}
									onChange={formikProps.handleChange}
									onBlur={formikProps.handleBlur}
									name="c2a.optOut.finalize"
									color="primary"
								/>}
								label="Finalize opting out of communication methods"
							/>
							<Typography style={{ filter: 'contrast(.4)' }} variant='body2'>
								Allow opting out of the fields with <DoneOutline fontSize="small" color="primary" />
							</Typography>
							<Paper variant='outlined' style={{ padding: '5px', maxWidth: 'max-content' }}>
								{["phone", "cell", "email", ...userContext.customFields.filter(x => x.isPoc).map(x => x.name)].map((availableField, index) =>
									<Chip
										style={{ margin: '3px' }}
										key={index}
										onClick={() => handleListItemClick(availableField, 'optOut')}
										icon={formikProps.values.c2a.optOut.fields.indexOf(availableField) !== -1 ? <DoneOutline fontSize="small" /> : null}
										label={availableField}
									/>)}
							</Paper>

							<TextField
								sx={{ width: '100%' }}
								margin="dense"
								label="Message"
								name="c2a.optOut.text"
								type="text"
								variant="outlined"
								multiline
								rows={12}
								value={formikProps.values.c2a?.optOut?.text ?? ''}
								onChange={formikProps.handleChange}
								onBlur={formikProps.handleBlur}
								error={formikProps.errors?.c2a?.optOut?.text && formikProps.touched?.c2a?.optOut?.text}
								helperText={formikProps.touched?.c2a?.optOut?.text && formikProps.errors?.c2a?.optOut?.text}
							/>
					</>}
			</Box>
		</Box>
	);
}
