import { useEffect, useState, useContext, useMemo } from "react";
import { Formik } from "formik";
import _ from "lodash";
import { array, mixed, number, object, string } from "yup";
import { Box, Card, CardContent, CardHeader, Tabs, Tab, FormControlLabel, Switch, Typography } from "@mui/material";
import { getPaymentProfile, listPaymentTemplates } from "src/graphql/queries";
import { createPaymentProfile, updatePaymentProfile } from "src/graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { UserContext } from "src/contexts/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import { PageAppBar } from "../../components/pageAppBar";
import { enqueueSnackbar } from "notistack";
import { InContact } from "src/contexts/InContact";
import Details from "src/components/omni/details";
import MessagingOptions from "src/components/omni/messaging";
import Styling from "src/components/omni/styling";
import { tabStyle } from "src/theme";
import C2APayment from "src/components/omni/c2aPayment";
import C2ACallMe from "src/components/omni/c2aCallMe";
import { C2AOptOut } from "src/components/omni/optOut";

const newProfile = {
	id: "",
	tenant: "",
	name: "",
	vendorId: "",
	apiKey: "",
	ttl: 30,
	header1: "",
	header2: "",
	siteLogo: "",
	sitePrimary: "#ffffff",
	siteSecondary: "#ffffff",
	supportEmail: "",
	supportPhone: "",
	promptPaySkills: [],
	c2a: {
		paymentEnabled: false,
		callMeNowEnabled: false,
		callMeLaterEnabled: false,
		callUsEnabled: false,
		optOutEnabled: false,
		optInEnabled: false,
		fileDownloadEnabled: false,
		urlRedirectEnabled: false,
		esignEnabled: false,
		payment: {
			dynamicDescriptor: "",
			agingInfo: 0,
			minRate: 0,
			minType: null,
			finalize: true,
			balanceReplacement: "",
			agingReplacement: "",
			invoiceReplacement: "",
		},
		callMeNow: {
			text: "",
			skillId: "",
			finalize: false,
			ivrScript: "",
		},
		callMeLater: {
			text: "",
			skillId: "",
			finalize: true,
		},
		callUs: {
			text: "",
			extension: "",
			pointOfContact: "",
			finalize: true,
		},
		optOut: {
			text: "",
			fields: [],
			finalize: true,
		},
		optIn: {
			text: "",
			fields: [],
			finalize: true,
		},
		fileDownload: {
			text: "",
			files: [],
		},
		urlRedirect: {
			text: "",
			redirects: [],
		},
		esign: {
			text: "",
			finalize: true,
			fields: [],
		},
	},
	channels: {
		emailEnabled: true,
		smsEnabled: true,
		email: {
			providerName: "",
			messageTemplate: "",
			subject: "",
			sendingEmail: "",
			sendingEmailDisplayName: "",
			emailSkill: "",
			emailIVRScript: "",
			awsRegion: "",
			awsAccessKey: "",
			awsSecretKey: ""
		},
		sms: {
			providerName: "",
			messageTemplate: "",
			smsSkill: "",
			smsIVRScript: "",
			sendingNumber: "",
			bearerToken: "",
			awsRegion: "",
			awsAccessKey: "",
			awsSecretKey: ""
		},
	},
};

Array.prototype.sortByAttribute = function (attribute) {
	this.sort((a, b) => {
		if (a[attribute] < b[attribute]) return -1;
		if (a[attribute] > b[attribute]) return 1;
		return 0;
	});
};

export function C2AProfile() {
	const client = generateClient();
	const userContext = useContext(UserContext);
	// const { showDialog } = useConfirmDialog();
	const navigate = useNavigate();
	const { id } = useParams();
	const [paymentProfile, setPaymentProfile] = useState(newProfile);
	const [initialProfile, setInitialProfile] = useState();
	const [availableMessageTemplates, setAvailableMessageTemplates] = useState(
		[]
	);

	/** The list of phone numbers available to send SMS messages from. */
	const [smsPhoneNumbers, setSmsPhoneNumbers] = useState([]);

	const [skills, setSkills] = useState([]);
	const [smsMessagingSkills, setSmsMessagingSkills] = useState([]);
	const [ivrScripts, setIvrScripts] = useState([]);
	const [emailMessagingSkills, setEmailMessagingSkills] = useState([]);
	const [outboundSkills, setOutboundSkills] = useState([]);
	const [contactsList, setContactsList] = useState([]);
	const [loading, setLoading] = useState({
		smsSkills: false,
		emailSkills: false,
		scripts: false,
		profileSkills: false,
		pointsOfContact: false,
		messageTemplates: false,
		smsPhoneNumbers: false,
	});
	const [saving, setSaving] = useState(false);
	const [messageType, setMessageType] = useState("message-only");
	const contactFields = useMemo(
		() => [
			{ value: "externalId", label: "External Id" },
			{ value: "firstName", label: "First Name" },
			{ value: "lastName", label: "Last Name" },
			{ value: "phone", label: "Phone" },
			{ value: "source", label: "Source" },
			{ value: "cell", label: "Cell" },
			{ value: "email", label: "Email" },
			{ value: "address1", label: "Address1" },
			{ value: "address2", label: "Address2" },
			{ value: "city", label: "City" },
			{ value: "state", label: "State" },
			{ value: "zip", label: "Zip" },
			/* { value: "expireDt", label: "Expiration Date" }, */
			{ value: "outboundCallerId", label: "Outbound Caller ID" },
			{ value: "optIns:sms", label: "SMS - Opt In" },
			{ value: "optIns:email", label: "Email - Opt In" },
			{ value: "optIns:voice", label: "Voice - Opt In" },
			{ value: "optOuts:sms", label: "SMS - Opt Out" },
			{ value: "optOuts:email", label: "Email - Opt Out" },
			{ value: "optOuts:voice", label: "Voice - Opt Out" },
			...userContext?.customFields?.map((customField) => {
				return {
					value: `custom:${customField.name}`,
					label: customField.displayName,
				};
			}),
		],
		[userContext.customFields]
	);

	const [currentActionTab, setCurrentActionTab] = useState(0);

	useEffect(() => {
		async function setExistingPaymentProfile() {
			const profileData = await client.graphql(
				{
					query: getPaymentProfile,
					variables: { id }
				}
			);
			if (profileData?.data?.getPaymentProfile) {
				profileData.data.getPaymentProfile.c2a =
					JSON.parse(profileData.data.getPaymentProfile.c2a) ?? {};
				profileData.data.getPaymentProfile.channels =
					JSON.parse(profileData.data.getPaymentProfile.channels) ?? {};

				// Separate the point of contact and the extension
				if (profileData.data.getPaymentProfile.c2a.callUs) {
					let poc = "";
					let extension = "";
					for (
						let i = 0;
						i <
						profileData.data.getPaymentProfile.c2a.callUs.pointOfContact.length;
						++i
					) {
						if (
							profileData.data.getPaymentProfile.c2a.callUs.pointOfContact.charAt(
								i
							) === "," ||
							profileData.data.getPaymentProfile.c2a.callUs.pointOfContact.charAt(
								i
							) === ";"
						) {
							extension =
								profileData.data.getPaymentProfile.c2a.callUs.pointOfContact.substring(
									i,
									profileData.data.getPaymentProfile.c2a.callUs.pointOfContact
										.length
								);
							break;
						}

						poc +=
							profileData.data.getPaymentProfile.c2a.callUs.pointOfContact.charAt(
								i
							);
					}

					profileData.data.getPaymentProfile.c2a.callUs.pointOfContact = poc;
					profileData.data.getPaymentProfile.c2a.callUs.extension = extension;
				}

				if (profileData.data.getPaymentProfile.c2a.paymentEnabled ||
					profileData.data.getPaymentProfile.c2a.callMeNowEnabled ||
					profileData.data.getPaymentProfile.c2a.callMeLaterEnabled ||
					profileData.data.getPaymentProfile.c2a.callUsEnabled ||
					profileData.data.getPaymentProfile.c2a.optOutEnabled ||
					profileData.data.getPaymentProfile.c2a.optInEnabled ||
					profileData.data.getPaymentProfile.c2a.fileDownloadEnabled ||
					profileData.data.getPaymentProfile.c2a.urlRedirectEnabled ||
					profileData.data.getPaymentProfile.c2a.esignEnabled) {
					setMessageType('action-message');

				}

				setPaymentProfile(profileData.data.getPaymentProfile);
			}
		}

		async function getData() {
			const promises = [];

			switch (userContext.telephonyProvider) {
				// case 'Genesys':
				// 	setLoading((loading) => ({
				// 		...loading,
				// 		messageTemplates: true,
				// 		smsNumbers: true,
				// 	}));

				// 	// Get the SMS numbers for outbound messages.
				// 	promises.push(
				// 		new Promise(async (resolve) => {
				// 			const token = (await Auth.currentSession()).getIdToken().getJwtToken();
				// 			const smsNumbers = await Genesys.getSmsNumbers(token, userContext.apiKey);
				// 			if (smsNumbers) {
				// 				setSmsPhoneNumbers([...smsNumbers]);
				// 			}
				// 			setLoading((loading) => ({
				// 				...loading,
				// 				smsNumbers: false,
				// 			}));
				// 			resolve();
				// 		})
				// 	);
				// 	break;
				case 'NiC':
					setLoading((loading) => ({
						...loading,
						smsSkills: true,
						emailSkills: true,
						scripts: true,
						profileSkills: true,
						pointsOfContact: true,
						messageTemplates: true,
					}));

					promises.push(
						new Promise(async (resolve) => {
							const smsMessageSkills = await InContact.getMessagingSkills(4);
							smsMessageSkills?.sortByAttribute("skillName");
							setSmsMessagingSkills([...smsMessageSkills]);
							setLoading((loading) => ({
								...loading,
								smsSkills: false,
							}));
							resolve();
						})
					);

					promises.push(
						new Promise(async (resolve) => {
							const emailSkills = (await InContact.getMessagingSkills(4))?.filter((x) => !x.isOutbound);
							emailSkills.sortByAttribute("skillName");
							setEmailMessagingSkills([...emailSkills]);

							setLoading((loading) => ({
								...loading,
								emailSkills: false,
							}));
							resolve();
						})
					);

					promises.push(
						new Promise(async (resolve) => {
							const scripts = await InContact.getScripts();
							scripts.sortByAttribute("scriptName");
							setIvrScripts(scripts);
							setLoading((loading) => ({
								...loading,
								scripts: false,
							}));

							resolve();
						})
					);

					promises.push(
						new Promise(async (resolve) => {
							const profileSkills = await InContact.getConfigurationIcSkills(4);
							profileSkills.sortByAttribute("skillName");
							setSkills(profileSkills);

							// Filter outbound skills from profile skills
							setOutboundSkills(profileSkills.filter((x) => x.isOutbound));
							setLoading((loading) => ({
								...loading,
								profileSkills: false,
							}));
							resolve();
						})
					);

					promises.push(
						new Promise(async (resolve) => {
							const pointsOfContactList = await InContact.getPointsOfContact();
							pointsOfContactList?.body?.pointsOfContact?.sortByAttribute(
								"contactDescription"
							);
							setContactsList(
								pointsOfContactList?.body?.pointsOfContact?.filter(
									(x) => x.mediaTypeId === 4
								) ?? []
							);

							setLoading((loading) => ({
								...loading,
								pointsOfContact: false,
							}));
							resolve();
						})
					);

					break;
				case 'Webex':
					setLoading((loading) => ({
						...loading,
						messageTemplates: true,
						smsNumbers: true,
					}));

					// Get the SMS numbers for outbound messages.
					promises.push(
						new Promise(async (resolve) => {
							// const token = (await Auth.currentSession()).getIdToken().getJwtToken()
							// const smsNumbers = await Webex.getSmsNumbers(token, userContext.apiKey);
							// if (smsNumbers) {
							//   setSmsPhoneNumbers([...smsNumbers]);
							// }
							setLoading((loading) => ({
								...loading,
								smsNumbers: false,
							}));
							resolve();
						})
					);
					break;
				case 'RingCX':
					setLoading((loading) => ({
						...loading,
						messageTemplates: true,
					}));
					break;
				case 'None':
					setLoading((loading) => ({
						...loading,
						messageTemplates: true,
					}));
					break;
				default:
					throw new Error(`Telephony provider ${userContext.telephonyProvider} not supported.`);
			}

			promises.push(
				new Promise(async (resolve) => {
					const paymentTemplates = await client.graphql({
						query: listPaymentTemplates

					}
					);
					setAvailableMessageTemplates(
						paymentTemplates.data?.listPaymentTemplates?.items ?? []
					);

					setLoading((loading) => ({
						...loading,
						messageTemplates: false,
					}));

					resolve();
				})
			);

			await Promise.all(promises);
			setLoading(false);
		}

		if (userContext.tenantId) {
			if (id !== "new") {
				setExistingPaymentProfile();
			} else if (id === "new") {
				setPaymentProfile(newProfile);
			}
			getData();
		}
	}, [id, userContext.tenantId]);

	async function handleClose(formikProps) {
		if (
			!(formikProps.dirty || checkReplacements(formikProps.values)) ||
			(await showDialog({
				children:
					"You have unsaved changes. Are you sure you want to leave this page?",
				confirmTxt: "Yes",
				cancelTxt: "Cancel",
			})) === DialogResult.Confirm
		) {
			navigate("/c2a-profiles");
		}
	}

	function checkReplacements(formikValues) {
		return formikValues.c2a.payment.balanceReplacement !== initialProfile?.c2a?.payment?.balanceReplacement ||
			formikValues.c2a.payment.agingReplacement !== initialProfile?.c2a?.payment?.agingReplacement ||
			formikValues.c2a.payment.invoiceReplacement !== initialProfile?.c2a?.payment?.invoiceReplacement;
	}

	return (
		<>


			<Formik
				initialValues={paymentProfile}
				enableReinitialize
				validationSchema={object({
					name: string()
						.required("A Profile name is required")
						.trim()
						.matches(/^[a-zA-Z0-9 -]+$/, "Please enter a valid profile name"),
					vendorId: string()
						.required("A vendor ID is required")
						.trim()
						.matches(/^[a-zA-Z0-9 -]+$/, "Please enter a valid vendor id"),
					ttl: number()
						.required("An expiration time is required")
						.integer("Expiration time must be an integer")
						.min(1, "Expiration time must be at least one minute"),
					promptPaySkills:
						userContext.telephonyProvider === "NiC" &&
						array(),
					siteLogo: string().trim().nullable(),
					header1: string().trim().nullable(),
					header2: string().trim().nullable(),
					channels: object({
						email: mixed().when("emailEnabled", {
							is: true,
							then: () => object({
								messageTemplate: string().trim(),
								sendingEmail: string()
									.trim(),
								sendingEmailDisplayName: string().trim(),
								emailSkill: string().trim(),
								emailIVRScript: string().trim(),
							}),
						}),
						sms: mixed().when("smsEnabled", {
							is: true,
							then: () => object({
								messageTemplate: string().trim(),
								smsSkill: string().trim(),
								smsIVRScript: string().trim(),
								smsPhoneNumbers: string().trim(),
							}),
						}),
					}),
					c2a: object({
						payment: mixed().when("paymentEnabled", {
							is: true,
							then: () => object({
								dynamicDescriptor: string()
									.trim()
									.required("Please specify a descriptor"),
								agingInfo: number()
									.min(
										1,
										"The expiration time must be at least one minute"
									)
									.integer(
										"The expiration time must be an integer"
									),
								minType: string()
									.trim()
									.required("Please specify a min pay type"),
								minRate: number().positive(
									"The min rate must be positive"
								),
								balanceReplacement: string()
									.trim()
									.required("Please specify the balance field"),
								agingReplacement: string()
									.trim(),
								invoiceReplacement: string()
									.trim()
							}),
						}),
						callMeNow: mixed().when("callMeNowEnabled", {
							is: true,
							then: () => object({
								text: string().trim().required("A message is required"),
								skillId: string()
									.trim()
									.required("Please specify an outbound skill id"),
								ivrScript: string()
									.trim()
									.required("Please specify a script"),
							}),
						}),
						callMeLater: mixed().when("callMeLaterEnabled", {
							is: true,
							then: () => object({
								text: string().trim().required("A message is required"),
								skillId: string()
									.trim()
									.required("Please specify an outbound skill id"),
							}),
						}),
						callUs: mixed().when("callUsEnabled", {
							is: true,
							then: () => object({
								text: string().trim().required("A message is required"),
								pointOfContact: string()
									.trim()
									.required("Please specify a point of contact"),
								extension: string()
									.trim()
									.notRequired()
									.matches(/^[0-9,;]*$/, "Please enter a valid extension"),
							}),
						}),
						optOut: mixed().when("optOutEnabled", {
							is: true,
							then: () => object({
								text: string().trim().required("A message is required"),
							}),
						}),
						optIn: mixed().when("optInEnabled", {
							is: true,
							then: () => object({
								text: string().trim().required("A message is required"),
							}),
						}),
						fileDownload: mixed().when("fileDownloadEnabled", {
							is: true,
							then: () => object({
								text: string().trim().required("A message is required"),
							}),
						}),
						urlRedirect: mixed().when("urlRedirectEnabled", {
							is: true,
							then: () => object({
								text: string().trim().required("A message is required"),
							}),
						}),
						esign: mixed().when("esignEnabled", {
							is: true,
							then: () => object({
								text: string().trim().required("A message is required"),
							}),
						}),
					}),
				})}
				onSubmit={async (originalValues) => {
					setSaving(true);
					const values = _.cloneDeep(originalValues);

					// eSign not supported yet.
					delete values.c2a.esign;

					if (values.c2a.callUsEnabled) {
						values.c2a.callUs.pointOfContact = `${values.c2a.callUs.pointOfContact}${values.c2a.callUs.extension}`;
						delete values.c2a.callUs.extension;
					}

					values.channels = JSON.stringify(values.channels); // metadata in schema
					values.c2a = JSON.stringify(values.c2a);

					let successMsg = "Payment Profile Updated";
					let mutation = updatePaymentProfile;

					// Delete values that do not need to passed
					delete values.createdAt;
					delete values.updatedAt;
					// We need to check what c2a sections have been selected and only send that information in the JSON.

					if (id === "new") {
						mutation = createPaymentProfile;
						values.tenant = userContext.tenantId;
						successMsg = "Payment Profile Created";
						delete values.id;
					}

					// dynamo does not support empty strings
					Object.keys(values).forEach((key) => {
						if (values[key] === "") {
							values[key] = null;
						}
					});

					try {
						const paymentProfileResult = await client.graphql({

							query: mutation,
							variables: { input: values }
						}
						);
						enqueueSnackbar(successMsg, { variant: "success" });
						const savedProfile =
							paymentProfileResult.data[
							id === "new" ? "createPaymentProfile" : "updatePaymentProfile"
							];
						// SHOULD THIS BE PARSED ON THE BACKEND?
						savedProfile.c2a = JSON.parse(savedProfile.c2a);

						savedProfile.channels = JSON.parse(savedProfile.channels);
						// COPIED LOGIC FROM ContactTabForm.js

						setPaymentProfile(savedProfile);
						setInitialProfile(savedProfile);
						if (id === "new") {
							navigate(`/digital-profiles/${savedProfile.id}`);
						}
					} catch (err) {
						console.error(err);
						enqueueSnackbar("Profile could not be saved", { variant: "error" });
					}
					setSaving(false);
				}}
			>
				{(formikProps) => (
					<form onSubmit={formikProps.handleSubmit}>

						{/* ⬇ Chandler, the error is something down here ⬇ */}

						<Box display="flex" flexDirection="column" gap={2}>

							<PageAppBar
								title={paymentProfile.name || 'New Digital Profile'}
								description="Update the content for your Digital profile"
								actionOneText="Save"
								actionOneHandler={() => {
									formikProps.handleSubmit();
								}}
								// actionOneDisabled={!formikProps.dirty}
								actionTwoText="Cancel"
								actionTwoHandler={() => {
									navigate('/digital-profiles');
								}}
							/>

							<Box display="grid" gridTemplateColumns="1fr 2fr" gap={2}>

								<Details
									formikProps={formikProps}
									availableMessageTemplates={availableMessageTemplates}
									skills={skills}
									loading={loading}
									messageType={messageType}
									setMessageType={setMessageType}
								/>

								<MessagingOptions
									formikProps={formikProps}
									availableMessageTemplates={availableMessageTemplates}
									emailMessagingSkills={emailMessagingSkills}
									smsMessagingSkills={smsMessagingSkills}
									smsPhoneNumbers={smsPhoneNumbers}
									ivrScripts={ivrScripts}
									loading={loading}
								/>

							</Box>

							{messageType === "action-message" && (

								<Box display="grid" gridTemplateColumns="1fr 2fr" gap={2}>
									<Styling formikProps={formikProps} />
									<Card variant='outlined'>
										<CardHeader title='C2A Actions' titleTypographyProps={{
											variant: "body2",
											color: "textSecondary",
										}} />
										<CardContent>
											<Box>
												<Tabs
													value={currentActionTab}
													onChange={(e, newValue) => setCurrentActionTab(newValue)}
													variant='scrollable'
													scrollButtons='auto'
												>
													<Tab sx={tabStyle} label={
														<Box display="flex" flexDirection="column" gap={1}>
															<span>Payment</span>
															<Box color="text.secondary" fontSize="0.75rem">
																<Typography sx={{ fontSize: 'x-small' }} color={formikProps.values.c2a?.paymentEnabled ? "primary" : "error.main"}>
																	{formikProps.values.c2a?.paymentEnabled ? "Enabled" : "Disabled"}
																</Typography>
															</Box>
														</Box>
													} />
													<Tab sx={tabStyle} label={
														<Box display="flex" flexDirection="column" gap={1}>
															<span>Opt Out</span>
															<Box color="text.secondary" fontSize="0.75rem">
																<Typography sx={{ fontSize: 'x-small' }} color={formikProps.values.c2a?.optOutEnabled ? "primary" : "error.main"}>
																	{formikProps.values.c2a?.optOutEnabled ? "Enabled" : "Disabled"}
																</Typography>
															</Box>
														</Box>
													} />
													<Tab sx={tabStyle} label={
														<Box display="flex" flexDirection="column" gap={1}>
															<span>Opt In</span>
															<Box color="text.secondary" fontSize="0.75rem">
																<Typography sx={{ fontSize: 'x-small' }} color={formikProps.values.c2a?.optInEnabled ? "primary" : "error.main"}>
																	{formikProps.values.c2a?.optInEnabled ? "Enabled" : "Disabled"}
																</Typography>
															</Box>
														</Box>
													} />
													<Tab sx={tabStyle} label={
														<Box display="flex" flexDirection="column" gap={1}>
															<span>File Download</span>
															<Box color="text.secondary" fontSize="0.75rem">
																<Typography sx={{ fontSize: 'x-small' }} color={formikProps.values.c2a?.fileDownloadEnabled ? "primary" : "error.main"}>
																	{formikProps.values.c2a?.fileDownloadEnabled ? "Enabled" : "Disabled"}
																</Typography>
															</Box>
														</Box>
													} />
													<Tab sx={tabStyle} label={
														<Box display="flex" flexDirection="column" gap={1}>
															<span>URL Redirect</span>
															<Box color="text.secondary" fontSize="0.75rem">
																<Typography sx={{ fontSize: 'x-small' }} color={formikProps.values.c2a?.urlRedirectEnabled ? "primary" : "error.main"}>
																	{formikProps.values.c2a?.urlRedirectEnabled ? "Enabled" : "Disabled"}
																</Typography>
															</Box>
														</Box>
													} />
													{userContext.telephonyProvider === 'NiC' && <Tab sx={tabStyle} label={
														<Box display="flex" flexDirection="column" gap={1}>
															<span>Call Me Now</span>
															<Box color="text.secondary" fontSize="0.75rem">
																<Typography sx={{ fontSize: 'x-small' }} color={formikProps.values.c2a?.callMeNowEnabled ? "primary" : "error.main"}>
																	{formikProps.values.c2a?.callMeNowEnabled ? "Enabled" : "Disabled"}
																</Typography>
															</Box>
														</Box>
													} />}
													{userContext.telephonyProvider === 'NiC' && <Tab sx={tabStyle} label={
														<Box display="flex" flexDirection="column" gap={1}>
															<span>Call Me Later</span>
															<Box color="text.secondary" fontSize="0.75rem">
																<Typography sx={{ fontSize: 'x-small' }} color={formikProps.values.c2a?.callMeLaterEnabled ? "primary" : "error.main"}>
																	{formikProps.values.c2a?.callMeLaterEnabled ? "Enabled" : "Disabled"}
																</Typography>
															</Box>
														</Box>
													} />}
													{userContext.telephonyProvider === 'NiC' && <Tab sx={tabStyle} label={
														<Box display="flex" flexDirection="column" gap={1}>
															<span>Call Us</span>
															<Box color="text.secondary" fontSize="0.75rem">
																<Typography sx={{ fontSize: 'x-small' }} color={formikProps.values.c2a?.callUsEnabled ? "primary" : "error.main"}>
																	{formikProps.values.c2a?.callUsEnabled ? "Enabled" : "Disabled"}
																</Typography>
															</Box>
														</Box>
													} />}
													{/* <Tab sx={tabStyle} label={
														<Box display="flex" flexDirection="column" gap={1}>
															<span>eSign</span>
															<Box color="text.secondary" fontSize="0.75rem">
																<Typography sx={{ fontSize: 'x-small' }} color={formikProps.values.c2a?.esignEnabled ? "primary" : "error.main"}>
																	{formikProps.values.c2a?.esignEnabled ? "Enabled" : "Disabled"}
																</Typography>
															</Box>
														</Box>
													} /> */}
												</Tabs>

												{/* Payments */}

												{currentActionTab === 0 && (
													<FormControlLabel
														control={
															<Switch
																checked={
																	formikProps.values.c2a?.paymentEnabled ??
																	false
																}
																onChange={formikProps.handleChange}
																name="c2a.paymentEnabled"
																color="primary"
															/>
														}
														label="Enabled"
													/>
												)}
												{currentActionTab === 0 && formikProps.values.c2a?.paymentEnabled && (
													<C2APayment
														formikProps={formikProps}
														contactFields={contactFields}
													/>
												)}

												{/* Opt Out */}

												{currentActionTab === 1 && (
													<FormControlLabel
														control={
															<Switch
																checked={
																	formikProps.values.c2a?.optOutEnabled ??
																	false
																}
																onChange={formikProps.handleChange}
																name="c2a.optOutEnabled"
																color="primary"
															/>
														}
														label="Enabled"
													/>
												)}

												{currentActionTab === 1 && formikProps.values.c2a?.optOutEnabled && (
													<C2AOptOut formikProps={formikProps} />
												)}

												{currentActionTab === 5 && (
													<FormControlLabel
														control={
															<Switch
																checked={
																	formikProps.values.c2a?.callMeNowEnabled ??
																	false
																}
																onChange={formikProps.handleChange}
																name="c2a.callMeNowEnabled"
																color="primary"
															/>
														}
														label="Enabled"
													/>
												)}
												{currentActionTab === 5 && formikProps.values.c2a?.callMeNowEnabled && (
													<C2ACallMe
														formikProps={formikProps}
														outboundSkills={outboundSkills}
														loading={loading}
														ivrScripts={ivrScripts}
													/>
												)}


											</Box>
										</CardContent>
									</Card>

								</Box>

							)}

							{/* <Accordion>
												<AccordionSummary
													expandIcon={<ExpandMoreOutlined />}
												>
													<Grid item>
														<FormControlLabel
															control={
																<Switch
																	checked={
																		formikProps.values.c2a?.callUsEnabled
																	}
																	onChange={formikProps.handleChange}
																	name="c2a.callUsEnabled"
																	color="primary"
																/>
															}
															label="Call Us"
														/>
													</Grid>
												</AccordionSummary>
												<AccordionDetails>
													<C2ACallUs
														formikProps={formikProps}
														contactsList={contactsList}
														loading={loading}
													/>
												</AccordionDetails>
											</Accordion> */}

							{/* <Accordion>
												<AccordionSummary
													expandIcon={<ExpandMoreOutlined />}
												>
													<Grid item>
														<FormControlLabel
															control={
																<Switch
																	checked={
																		formikProps.values.c2a.fileDownloadEnabled
																	}
																	onChange={formikProps.handleChange}
																	name="c2a.fileDownloadEnabled"
																	color="primary"
																/>
															}
															label="File Downloads"
														/>
													</Grid>
												</AccordionSummary>
												<AccordionDetails>
													<C2AFileDownload
														formikProps={formikProps}
													/>
												</AccordionDetails>
											</Accordion> */}

							{/* <Accordion>
												<AccordionSummary
													expandIcon={<ExpandMoreOutlined />}
												>
													<Grid item>
														<FormControlLabel
															control={
																<Switch
																	checked={
																		formikProps.values.c2a?.optInEnabled ??
																		false
																	}
																	onChange={formikProps.handleChange}
																	name="c2a.optInEnabled"
																	color="primary"
																/>
															}
															label="Opt In"
														/>
													</Grid>
												</AccordionSummary>
												<AccordionDetails>
													<C2AOptIn
														formikProps={formikProps}
													/>
												</AccordionDetails>
											</Accordion> */}

							{/* <Accordion>
												<AccordionSummary
													expandIcon={<ExpandMoreOutlined />}
												>
													<Grid item>
														<FormControlLabel
															control={
																<Switch
																	checked={
																		formikProps.values.c2a?.optOutEnabled ??
																		false
																	}
																	onChange={formikProps.handleChange}
																	name="c2a.optOutEnabled"
																	color="primary"
																/>
															}
															label="Opt Out"
														/>
													</Grid>
												</AccordionSummary>
												<AccordionDetails>
													<C2AOptOut
														formikProps={formikProps}
													/>
												</AccordionDetails>
											</Accordion> */}

							{/* <Accordion>
												<AccordionSummary
													expandIcon={<ExpandMoreOutlined />}
												>
													<Grid item>
														<FormControlLabel
															control={
																<Switch
																	checked={
																		formikProps.values?.c2a
																			?.callMeLaterEnabled ?? false
																	}
																	onChange={formikProps.handleChange}
																	name="c2a.callMeLaterEnabled"
																	color="primary"
																/>
															}
															label="Schedule Callback"
														/>
													</Grid>
												</AccordionSummary>
												<AccordionDetails>
													<C2AScheduleCallBack
														formikProps={formikProps}
														outboundSkills={outboundSkills}
														loading={loading}
													/>
												</AccordionDetails>
											</Accordion> */}

							{/* <Accordion>
												<AccordionSummary
													expandIcon={<ExpandMoreOutlined />}
												>
													<Grid item>
														<FormControlLabel
															control={
																<Switch
																	checked={
																		formikProps.values.c2a.urlRedirectEnabled
																	}
																	onChange={formikProps.handleChange}
																	name="c2a.urlRedirectEnabled"
																	color="primary"
																/>
															}
															label="URL Redirects"
														/>
													</Grid>
												</AccordionSummary>
												<AccordionDetails>
													<C2AUrlRedirect
														formikProps={formikProps}
													/>
												</AccordionDetails>
											</Accordion> */}
						</Box>
					</form>
				)}
			</Formik>
		</>
	);
}
