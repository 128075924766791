import { useEffect, useContext, useState } from 'react';
import {
	Box,
	CircularProgress
} from '@mui/material';
import {
	EditOutlined,
	DeleteOutlineOutlined,
	ScheduleOutlined,
	LayersClearOutlined,
	PlayArrowOutlined,
	ControlPointDuplicateOutlined,
	StopOutlined,
	PauseCircleOutline,
	RestoreOutlined,
	DashboardOutlined,
} from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import { UserContext } from '../../contexts/UserContext';
import { del, generateClient, post } from 'aws-amplify/api';
import { deleteCampaign } from '../../graphql/mutations';
import { campaignsByTenant } from '../../graphql/queries';
import { PageAppBar } from 'src/components/pageAppBar';
import { ConfirmDialog } from 'src/components/confirmDialog/confirmDialog';
import { fetchAuthSession } from 'aws-amplify/auth';
import { enqueueSnackbar } from 'notistack';
import { CampaignDialog } from '../../components/engagement/campaignDialog';
import { onTenantCreateCampaign, onTenantDeleteCampaign, onTenantUpdateCampaign } from 'src/graphql/subscriptions';
import CampaignStatus from 'src/components/engagement/campaignStatus';
import CampaignScheduleDialog from 'src/components/engagement/campaignScheduleDialog';

export function Campaigns() {
	const client = generateClient();
	const userContext = useContext(UserContext);

	// Data States
	const [loading, setLoading] = useState(false);
	const [campaigns, setCampaigns] = useState([]);
	const [toDelete, setToDelete] = useState(null);
	const [toUpdate, setToUpdate] = useState(null);
	const [campaignsUpdating, setCampaignsUpdating] = useState([]);

	// Dialog Controls
	const [stopConfirmOpen, setStopConfirmOpen] = useState(false);
	const [campaignDialogOpen, setCampaignDialogOpen] = useState(false);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
	const [campaignStatusOpen, setCampaignStatusOpen] = useState(false);
	const [scheduleOpen, setScheduleOpen] = useState(false);
	const [scheduleCampaign, setScheduleCampaign] = useState(null);
	const [resetConfirmOpen, setResetConfirmOpen] = useState(false);
	const [confirmClearCompletedOpen, setConfirmClearCompletedOpen] = useState(false);

	// Set up subscriptions
	useEffect(() => {
		if (userContext.tenantId) {
			const createCampaignSubscription = client.graphql({
				query: onTenantCreateCampaign,
				variables: { tenant: userContext.tenantId },
			}).subscribe({
				next: (data) => {
					const newCampaign = data.data.onTenantCreateCampaign;
					setCampaigns((prevCampaigns) => [newCampaign, ...prevCampaigns]);
					enqueueSnackbar(`${newCampaign.name ? newCampaign.name : 'Campaign'} Created`, {
						variant: 'success',
						autoHideDuration: 500
					})
				},
			});

			const updateCampaignSubscription = client
				.graphql({
					query: onTenantUpdateCampaign,
					variables: { tenant: userContext.tenantId },
				})
				.subscribe({
					next: (data) => {
						const updatedCampaign = data.data.onTenantUpdateCampaign;
						console.log('Updated Campaign', updatedCampaign);
						updateToast(updatedCampaign, campaigns);
						getCampaigns(updatedCampaign);
					},
				});

			const deleteCampaignSubscription = client
				.graphql({
					query: onTenantDeleteCampaign,
					variables: { tenant: userContext.tenantId },
				})
				.subscribe({
					next: (data) => {
						const deletedCampaign = data.data.onTenantDeleteCampaign;
						setCampaigns((prevCampaigns) =>
							prevCampaigns.filter(
								(campaign) => campaign.id !== deletedCampaign.id,
							),
						);
						enqueueSnackbar(`${deletedCampaign.name ? deletedCampaign.name : 'Campaign'} Deleted`, {
							variant: 'success',
							autoHideDuration: 5000,
						});
					},
				});

			console.log('Subscribed to Campaigns');

			return () => {
				createCampaignSubscription.unsubscribe();
				updateCampaignSubscription.unsubscribe();
				deleteCampaignSubscription.unsubscribe();

				console.log('Unsubscribed from Campaigns');
			};
		}

	}, [campaigns]);

	async function getCampaigns(updatedCampaign) {
		try {
			const savedCampaigns = await client.graphql({
				query: campaignsByTenant,
				variables: { tenant: userContext.tenantId },
			});
			if (savedCampaigns && savedCampaigns.data) {
				setCampaigns(savedCampaigns.data.campaignsByTenant.items.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)));
			} else {
				setCampaigns([]);
			}
			if (updatedCampaign) {
				// Remove updated campaign from the campaignsUpdating array
				setCampaignsUpdating((prevCampaignsUpdating) =>
					prevCampaignsUpdating.filter((campaign) => campaign !== updatedCampaign.id),
				);
			}
		} catch (err) {
			console.error('Error fetching campaigns', err);
			enqueueSnackbar('Error fetching campaigns', { variant: 'error	' });
		}
	}

	// Fetch Campaigns
	useEffect(() => {
		async function getData() {
			setLoading(true);
			await getCampaigns();
			setLoading(false);
		}
		if (userContext.tenantId) {
			getData();
		}
	}, [userContext.tenantId]);

	function updateToast(newCampaignData, oldCampaignData) {
		const oldData = oldCampaignData.find((campaign) => campaign.id === newCampaignData.id);

		if (oldData && newCampaignData && oldData?.status !== newCampaignData?.status) {
			switch (newCampaignData.status) {
				case 'Active':
					enqueueSnackbar(`${newCampaignData.name} Started`, {
						variant: 'success',
						autoHideDuration: 5000,
					});
					break;
				case 'Paused':
					enqueueSnackbar(`${newCampaignData.name} Paused`, {
						variant: 'info',
						autoHideDuration: 5000,
					});
					break;
				case 'Completed':
					enqueueSnackbar(`${newCampaignData.name} Completed`, {
						variant: 'success',
						autoHideDuration: 5000,
					});
					break;
				case 'Pending':
					enqueueSnackbar(`${newCampaignData.name} Reset`, {
						variant: 'info',
						autoHideDuration: 5000,
					});
					break;
				default:
					break;
			}
		} else {
			enqueueSnackbar(`${newCampaignData.name} Updated`, {
				variant: 'info',
				autoHideDuration: 5000,
			});
		}
	}


	// Get what action should be displayed on the play button
	const getPlayActionInfo = (rowData) => {
		const isMidStatus = campaignsUpdating.includes(rowData.id);
		switch (rowData.status) {
			case "Pending":
				return {
					disabled: isMidStatus,
					icon: () => (<PlayArrowOutlined color={isMidStatus ? "disabled" : "primary"} />),
					tooltip: isMidStatus ? '' : 'Start Campaign',
					onClick: (_, rowData) => {
						startCampaign(rowData);
						setCampaignsUpdating([...campaignsUpdating, rowData.id]);
					}
				};

			case "Active":
				return {
					disabled: isMidStatus,
					icon: () => (<PauseCircleOutline color={isMidStatus ? "disabled" : "primary"} />),
					tooltip: isMidStatus ? '' : 'Pause Campaign',
					onClick: (_, rowData) => {
						pauseCampaign(rowData);
						setCampaignsUpdating([...campaignsUpdating, rowData.id]);
					}

				};

			case "Paused":
				return {
					disabled: isMidStatus,
					icon: () => (<PlayArrowOutlined color={isMidStatus ? "disabled" : "primary"} />),
					tooltip: isMidStatus ? '' : 'Restart Campaign',
					onClick: (_, rowData) => {
						restartCampaign(rowData);
						setCampaignsUpdating([...campaignsUpdating, rowData.id]);
					}
				};

			default:
				return {
					disabled: true,
					icon: () => (<PlayArrowOutlined color='disabled' />),
				};
		}
	}

	// Check if the skill and segments are in use by another campaign
	async function checkCampaignIntegrity(campaignData) {
		const campaigns = await client.graphql({
			query: campaignsByTenant,
			variables: {
				tenant: userContext.tenantId,
				filter: {
					or: [{ status: { eq: 'Active' } }, { status: { eq: 'Paused' } }],
				},
			},
		});

		let campaignDataSegments = campaignData.segments;
		if (!Array.isArray(campaignDataSegments)) {
			campaignDataSegments = JSON.parse(campaignDataSegments);
		}

		if (campaigns.data?.campaignsByTenant?.items) {
			return !campaigns.data.campaignsByTenant.items.some(
				(x) =>
					x.id !== campaignData.id &&
					((+x.skillId && +x.skillId === +campaignData.skillId) ||
						campaignDataSegments.some((curSegment) =>
							JSON.parse(x.segments).some(
								(segment) => segment.segmentId === curSegment.segmentId,
							),
						)),
			);
		}
	}

	// Clone Campaign
	async function cloneCampaign(data) {
		const cloneCampaign = JSON.parse(JSON.stringify(data));
		cloneCampaign.name += ' - Clone';
		cloneCampaign.status = 'Pending';
		cloneCampaign.expireDate = new Date('01/01/2099').toISOString();
		delete cloneCampaign.id;
		delete cloneCampaign.campaignSchedule;
		delete cloneCampaign.segmentSchedule;
		setToUpdate(cloneCampaign)
		setCampaignDialogOpen(true);
	}

	async function handleDeleteCampaign() {
		try {
			setLoading(true);
			// Delete Campaign Schedules if they exist
			if (toDelete.segmentSchedule) {
				const schedules = JSON.parse(toDelete.segmentSchedule);
				for (const schedule of schedules) {
					await post({
						apiName: 'cdyxoutreach',
						path: '/approver/createSchedule',
						options: {
							headers: {
								Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
								'x-api-key': userContext.apiKey,
							},
							body: {
								delete: true,
								event: schedule,
								tenant: userContext.tenantId,
								campaignId: toDelete.id,
							},
						}
					}).response;
				}
			}

			await client.graphql({
				query: deleteCampaign,
				variables: { input: { id: toDelete.id } },
			});

			setConfirmDeleteOpen(false);
			setLoading(false);
			setToDelete(null);

		} catch (err) {
			console.log('Error deleting campaign', err);
			enqueueSnackbar('Error deleting campaign', { variant: 'error' });
		}
	}

	async function handleEndCampaign(campaignData) {
		try {
			setStopConfirmOpen(false);

			await del({
				apiName: 'cdyxoutreach',
				path: `/cache/delete/${campaignData.id}/${campaignData.skillId}`,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
				},
			}).response;

		} catch (err) {
			console.log('Error ending campaign', err)
			enqueueSnackbar('Error ending campaign', { variant: 'error' });
		}

	}

	async function handleResetCampaign() {
		try {
			await post({
				apiName: 'cdyxoutreach',
				path: `/cache/reset/${toUpdate.id}`,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
				},
			}).response;

		} catch (err) {
			console.log('Error resetting campaign', err)
			enqueueSnackbar('Error resetting campaign', { variant: 'error' });
		}

		setResetConfirmOpen(false);
		setToUpdate(null);

	}

	async function handleClearCompletedContacts() {
		try {
			await del({
				apiName: 'cdyxoutreach',
				path: `/cache/clear-completed/${toUpdate.id}/`,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
				},
			}).response;

			enqueueSnackbar('Completed contacts cleared successfully', { variant: 'success' });
		} catch (err) {
			console.log('Error clearing completed contacts', err)
			enqueueSnackbar('Error clearing completed contacts', { variant: 'error' });
		}
		setConfirmClearCompletedOpen(false);
		setToUpdate(null);
	}

	function handleSearchChange(value) {
		window.sessionStorage.setItem('CampaignsSearch', value);
	}

	async function pauseCampaign(campaign) {
		try {
			const pause = await post({
				apiName: 'cdyxoutreach',
				path: `/cache/pause/${campaign.id}/${campaign.skillId}`,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
				}
			}).response;

		} catch (err) {
			console.error('Error pausing campaign', err);
			enqueueSnackbar('Error pausing campaign', { variant: 'error' });
		}
	}

	async function restartCampaign(campaign) {
		try {
			const restart = await post({
				apiName: 'cdyxoutreach',
				path: `/cache/restart/${campaign.id}/${campaign.skillId}`,
				options: {
					headers: {
						Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
						'x-api-key': userContext.apiKey,
					},
				}

			}).response;
		} catch (err) {
			console.error('Error restarting campaign', err);
			enqueueSnackbar('Error restarting campaign', { variant: 'error' });
		}
	}

	async function startCampaign(campaign) {
		try {
			const ready = await checkCampaignIntegrity(campaign);

			if (ready) {
				const start = await post({
					apiName: 'cdyxoutreach',
					path: `/cache/create/`,
					options: {
						headers: {
							Authorization: `Bearer ${(await fetchAuthSession()).tokens.idToken}`,
							'x-api-key': userContext.apiKey,
						},
						body: {
							campaignId: campaign.id,
							dataSource: 'beast'
						},
					}
				}).response;

			} else {
				setCampaignsUpdating(campaignsUpdating.filter((id) => id !== campaign.id));
				enqueueSnackbar(
					'The skill and/or one or more segments are in use by another campaign',
					{ variant: 'error', autoHideDuration: 5000 },
				);
			}
		} catch (err) {
			console.error('Error starting campaign', err);
			enqueueSnackbar('Error starting campaign', { variant: 'error' });
		}
	}

	return (
		<Box>
			<>
				<PageAppBar
					title="Campaigns"
					description="View, Create, Edit, and Delete Contact Campaigns"
					actionOneText={!campaignStatusOpen ? "+ Campaign" : "View All Campaigns"}
					actionOneHandler={() => {
						if (!campaignStatusOpen) {
							setToUpdate(null);
							setCampaignDialogOpen(true);
						} else {
							setCampaignStatusOpen(false);
							setToUpdate(null);
						}
					}}
				/>
				{!campaignStatusOpen && (<MaterialTable
					components={{
						Container: (props) => <Box {...props} elevation={0} />,
					}}
					title=""
					data={campaigns.filter((campaign) => campaign.tenant === userContext.tenantId)}
					columns={[
						{ title: 'Name', field: 'name' },
						{ title: 'Skill', field: 'skillName' },
						{ title: 'Profile', field: 'profile.name' },
						{
							title: 'Status', field: 'status', render: (rowData) =>
								<Box display="flex">
									{!campaignsUpdating.includes(rowData.id) && rowData.status}
									{campaignsUpdating.includes(rowData.id) && <CircularProgress align size={20} />}
								</Box>

						},
					]}
					isLoading={loading}
					options={{
						actionsColumnIndex: -1,
						pageSize: 10,
						searchText: window.sessionStorage.getItem('CampaignsSearch'),
						searchFieldStyle: {
							marginBottom: '16px',
							marginleft: '-28px',
						},
						headerStyle: {
							borderTop: '1px solid #e0e0e0',
							padding: '16px',
						},
						searchFieldVariant: 'outlined',
						paginationType: 'stepped',
					}}
					onSearchChange={handleSearchChange}
					actions={[
						rowData => function () {
							const disabled = rowData.status === 'Pending' || rowData.status === 'Completed' || rowData.status === 'Expired';
							return {
								disabled,
								icon: () => (<DashboardOutlined color={disabled ? 'disabled' : 'primary'} />),
								tooltip: disabled ? '' : 'Campaign Dashboard',
								onClick: (event, rowData) => {
									setCampaignStatusOpen(true);
									setToUpdate(rowData);
								}
							};
						}(),
						rowData => getPlayActionInfo(rowData),
						rowData => function () {
							const disabled = rowData.status === "Pending" || rowData.status === "Completed" || rowData.status === "Expired" || campaignsUpdating.includes(rowData.id);
							return {
								disabled,
								icon: () => (<StopOutlined color={disabled ? 'disabled' : 'primary'} />),
								tooltip: disabled ? '' : 'End Campaign',
								onClick: (_, rowData) => {
									setToUpdate(rowData);
									setStopConfirmOpen(true);
								}
							}
						}(),
						rowData => function () {
							const disabled = rowData.status === 'Pending' || rowData.status === 'Completed' || rowData.status === 'Expired';
							return ({
								disabled,
								icon: () => (<LayersClearOutlined color={disabled ? "disabled" : "primary"} />),
								tooltip: disabled ? '' : 'Clear Completed Contacts from Campaign',
								onClick: (_, rowData) => {
									setToUpdate(rowData);
									setConfirmClearCompletedOpen(true);
								}
							});
						}(),
						rowData => function () {
							const disabled = rowData.status === 'Completed' || rowData.status === 'Expired';
							return ({
								disabled,
								icon: () => (
									<RestoreOutlined color={disabled ? "disabled" : "primary"} />
								),
								tooltip: disabled ? '' : 'Reset Campaign',
								onClick: (_, rowData) => {
									setResetConfirmOpen(true);
									setToUpdate(rowData);
								}
							});
						}(),
						rowData => function () {
							const disabled = rowData.status === 'Completed' || rowData.status === 'Expired';
							return ({
								disabled,
								icon: () => (
									<ScheduleOutlined color={disabled ? "disabled" : "primary"} />
								),
								tooltip: disabled ? '' : 'Schedule Campaign',
								onClick: (_, rowData) => {
									setScheduleCampaign(rowData);
									setScheduleOpen(true);
								}
							});
						}(),
						rowData => function () {
							const disabled = campaignsUpdating.includes(rowData.id);
							return ({
								disabled,
								icon: () => (<ControlPointDuplicateOutlined color={disabled ? "disabled" : "primary"} />),
								tooltip: disabled ? '' : 'Clone Campaign',
								onClick: (_, rowData) => {
									cloneCampaign(rowData);

								}
							});
						}(),
						rowData => function () {
							const disabled = rowData.status === 'Completed' || rowData.status === "Expired" || campaignsUpdating.includes(rowData.id);
							return ({
								disabled,
								icon: () => (<EditOutlined color={disabled ? 'disabled' : 'primary'} />),
								tooltip: disabled ? '' : 'Edit Campaign',
								onClick: (_, rowData) => {
									setToUpdate(rowData);
									setCampaignDialogOpen(true);
								}
							});
						}(),
						rowData => function () {
							const disabled = campaignsUpdating.includes(rowData.id);
							return ({
								disabled,
								icon: () => (<DeleteOutlineOutlined color={disabled ? "disabled" : "primary"} />),
								tooltip: disabled ? '' : 'Delete Campaign',
								onClick: (_, rowData) => {
									setToDelete(rowData);
									setConfirmDeleteOpen(true);
								}
							});
						}(),
					]}
					onRowClick={(event, rowData) => {
						console.log(rowData);
					}}
				/>)}
			</>
			<ConfirmDialog
				open={confirmDeleteOpen}
				title="Delete Campaign"
				description="Are you sure you want to delete this campaign?"
				actionOneText="Delete"
				actionOneHandler={() => {
					handleDeleteCampaign();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					setToDelete(null);
					setConfirmDeleteOpen(false);
				}}
			/>

			{campaignStatusOpen && <CampaignStatus
				campaign={toUpdate}
			/>}
			<ConfirmDialog
				open={stopConfirmOpen}
				title="End Campaign"
				description="Are you sure you want to end this campaign?"
				actionOneText="End"
				actionOneHandler={() => {
					handleEndCampaign(toUpdate);
					setCampaignsUpdating([...campaignsUpdating, toUpdate.id]);
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					setStopConfirmOpen(false);
				}}
			/>
			<ConfirmDialog
				open={resetConfirmOpen}
				title="Reset Campaign"
				description="Are you sure you want to reset this campaign?"
				actionOneText="Reset"
				actionOneHandler={() => {
					handleResetCampaign();
					setCampaignsUpdating([...campaignsUpdating, toUpdate.id]);
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					setResetConfirmOpen(false);
				}}
			/>
			<ConfirmDialog
				open={confirmClearCompletedOpen}
				title="Clear Completed Contacts"
				description="Are you sure you want to clear completed contacts from this campaign?"
				actionOneText="Clear"
				actionOneHandler={() => {
					handleClearCompletedContacts();
				}}
				actionTwoText="Cancel"
				actionTwoHandler={() => {
					setConfirmClearCompletedOpen(false);
				}}
			/>
			<CampaignDialog
				open={campaignDialogOpen}
				setOpen={setCampaignDialogOpen}
				campaign={toUpdate}
			/>
			<CampaignScheduleDialog
				open={scheduleOpen}
				campaign={scheduleCampaign}
				onCancel={() => setScheduleOpen(false)}
			/>
		</Box>
	);
}
