import { memo, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import {
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Typography,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	FormHelperText,
	Box,
	Card,
	CardHeader,
	CardContent,
} from '@mui/material';
import { RetryDelayPicker } from './retryDelayPicker';
import _ from 'lodash';
import { listPaymentProfiles, tenantC2AProfiles } from '../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { Formik } from 'formik';
import { CadenceEditor } from './cadenceEditor';
import { useConfirmDialog } from '../confirmDialog/context';
import { string, object, number } from 'yup';
import { InContact } from '../../contexts/InContact';
import { useParams } from 'react-router-dom';

/**
 * The ChartDialog component is used Create New Charts
 * Expects No params our query string values on the route
 * * @category Pages
 *  @component
 *
 */
export function ChannelDialog(props) {
	const client = generateClient();
	const { open, onClose, channelData, onChange } = props;
	const userContext = useContext(UserContext);
	const { id } = useParams();

	const [channel, setChannel] = useState({});
	const [openCustom, setOpenCustom] = useState(false);
	const [paymentProfiles, setPaymentProfiles] = useState([]);
	const [isEditingCustomRetryIndex, setIsEditingCustomRetryIndex] =
		useState(-1);
	const [usingCadence, setUsingCadence] = useState(true);
	const [customRetriesExpanded, setCustomRetriesExpanded] = useState(false);
	const [cadenceExpanded, setCadenceExpanded] = useState(false);
	const [dialingScripts, setDialingScripts] = useState([]);

	useEffect(() => {
		async function getData() {
			const profiles = await client.graphql({
				query: listPaymentProfiles,
				variables: { tenantId: userContext.tenantId },
			});
			console.log(profiles);
			if (profiles && profiles.data) {
				setPaymentProfiles(profiles.data.listPaymentProfiles.items);
			}
		}

		if (open && userContext.tenantId) {
			getData();
		}
	}, [open, userContext.tenantId]);

	useEffect(() => {
		async function getData() {
			const clone = _.cloneDeep(channelData);

			setUsingCadence(Boolean(clone?.cadence));
			setCustomRetriesExpanded(false);
			setCadenceExpanded(false);

			clone.cadence = JSON.parse(clone?.cadence ?? '[]');
			clone.custRetry = JSON.parse(clone?.custRetry ?? '[]');
			if (clone.type !== 'Voice') {
				setUsingCadence(false);
			}
			setChannel({ dialingScript: '', ...clone });
		}

		if (channelData && open) {
			getData();
		}
	}, [open, channelData]);

	useEffect(() => {
		async function scriptResults() {
			const scriptPromise = [];
			scriptPromise.push(InContact.getScripts());
			const results = await Promise.all(scriptPromise);
			const sortedScripts = _.sortBy(results[0], ['scriptName']);
			setDialingScripts(sortedScripts);
		}

		if (userContext?.tenantId && id && userContext.telephonyProvider === 'NiC') {
			scriptResults();
		}
	}, [userContext.telephonyProvider, userContext?.tenantid, id]);

	const toggleCustom = () => {
		setOpenCustom(!openCustom);
	};

	function addRetry(retry, formikProps) {
		if (isEditingCustomRetryIndex === -1) {
			formikProps.setFieldValue('custRetry', [
				...formikProps.values.custRetry,
				retry,
			]);
		} else {
			formikProps.setFieldValue(
				`custRetry[${isEditingCustomRetryIndex}`,
				retry,
			);
		}

		toggleCustom();
	}

	const deleteCustRetry = (index, formikProps) => {
		formikProps.setFieldValue(
			'custRetry',
			formikProps.values.custRetry.filter((_, i) => i !== index),
		);
	};

	function handleEditCustomRetry(index) {
		setIsEditingCustomRetryIndex(index);
		toggleCustom();
	}

	function handleNewCustomRetry() {
		setIsEditingCustomRetryIndex(-1);
		toggleCustom();
	}

	function handleCustomRetriesToggle(e) {
		e.stopPropagation();

		setCustomRetriesExpanded(customRetriesExpanded && usingCadence);
		setUsingCadence(!usingCadence);
	}

	function handleCadenceToggle(e) {
		e.stopPropagation();

		setCadenceExpanded(cadenceExpanded && !usingCadence);
		setUsingCadence(!usingCadence);
	}

	function handleExpandToggleCustomRetries() {
		setCustomRetriesExpanded(!usingCadence && !customRetriesExpanded);
	}

	function handleExpandToggleCadence() {
		setCadenceExpanded(usingCadence && !cadenceExpanded);
	}

	async function handleClose(dirty) {
		onClose();
	}

	return (
		<>
			<Dialog open={open} maxWidth="lg">
				<DialogTitle id="dp-dialog-title">{`New ${channelData?.type} Channel`}</DialogTitle>
				<DialogContent>
					<Formik
						enableReinitialize
						initialValues={channel}
						validationSchema={object({
							paymentProfileId: string().when('type', {
								is: (val) => val !== 'Voice',
								then: () => string().required('Please select a Digital profile'),
								otherwise: () => string().nullable(),
							}),
							retryDelay: number().min(
								1,
								'The retry delay must be at least 1 minute',
							),
							dialingScript: string().when('type', {
								is: (val) =>
									val === 'Voice' && userContext.telephonyProvider === 'NiC',
								then: () => string().required('Please add a dialing script'),
								otherwise: () => string().nullable(),
							}),
						})}
						onSubmit={async (values) => {
							const clone = _.cloneDeep(values);

							if (usingCadence) {
								delete clone.custRetry;

								if (clone.cadence.length === 0) {
									clone.cadence.push({
										phoneType: 'phone',
										delay: 1,
										order: 1,
									});
								}

								clone.cadence = JSON.stringify(clone.cadence);
							} else {
								delete clone.cadence;
								clone.custRetry = JSON.stringify(clone.custRetry);
							}

							onChange(_.cloneDeep(clone));

							clone.cadence = JSON.parse(clone.cadence ?? '[]');
							clone.custRetry = JSON.parse(clone.custRetry ?? '[]');
							setChannel({ ...clone });
							onClose();
						}}
					>
						{(formikProps) => (
							<Box display="flex" flexDirection="column" gap={2}>
								{formikProps.values.type !== 'Voice' && (
									<Grid item>
										<Typography variant="caption">
											Retries `&apos;` activated till ttl has been met
										</Typography>
									</Grid>
								)}
								<RetryDelayPicker
									name="retryDelay"
									label="Retry Delay"
									formikProps={formikProps}
								/>
								<Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
									<TextField
										margin="dense"
										name="maxAttempts"
										label="Max Attempts"
										type="number"
										required
										value={formikProps.values.maxAttempts}
										onChange={formikProps.handleChange}
										InputProps={{
											inputProps: {
												max: 300,
												min: 1,
											},
										}}
									/>
									{formikProps.values.type === 'Voice' && (
										<TextField
											margin="dense"
											name="maxContacts"
											label="Max Contacts"
											type="number"
											required
											value={formikProps.values.maxContacts}
											onChange={formikProps.handleChange}
											InputProps={{
												inputProps: {
													max: 300,
													min: 1,
												},
											}}
										/>
									)}
								</Box>
								{formikProps.values.type !== 'Voice' && (
									<FormControl
										required
										error={
											formikProps.errors?.paymentProfileId &&
											formikProps.touched?.paymentProfileId
										}
									>
										<InputLabel>Digital Profile</InputLabel>
										<Select
											label="Digital Profile"
											name="paymentProfileId"
											value={formikProps.values.paymentProfileId}
											onChange={(e) => {
												formikProps.handleChange(e);
												formikProps.setFieldValue(
													'paymentProfileName',
													paymentProfiles.find(
														(x) => x.id === e.target.value,
													)?.name ?? e.target.value
												);
											}}
											onBlur={formikProps.handleBlur}
										>
											{paymentProfiles?.map((data, index) => (
												<MenuItem key={index} value={data.id}>
													{data.name}
												</MenuItem>
											))}
										</Select>
										{formikProps.errors?.paymentProfileId &&
											formikProps.touched?.paymentProfileId && (
												<FormHelperText error>
													{formikProps.errors.paymentProfileId}
												</FormHelperText>
											)}
									</FormControl>
								)}
								{formikProps.values.type === 'Voice' && (
									<Card elevation={0} variant='outlined'>
										<CardHeader title="Cadence" titleTypographyProps={{
											variant: "body2",
											color: "textSecondary",
										}} />
										<CardContent>
											<CadenceEditor
												cadence={formikProps.values.cadence}
												onChange={(cadence) =>
													formikProps.setFieldValue('cadence', cadence)
												}
											/>
										</CardContent>
									</Card>

								)}
								{formikProps.values.type === 'Voice' &&
									userContext.telephonyProvider === 'NiC' && (
										<Grid item>
											<ScriptSelect
												touched={formikProps.touched?.dialingScript}
												error={formikProps.errors?.dialingScript}
												onChange={formikProps.handleChange}
												onBlur={formikProps.handleBlur}
												dialingScripts={dialingScripts}
												value={formikProps.values?.dialingScript}
											/>
										</Grid>
									)}
								<Grid item>
									<Grid
										container
										direction="row"
										justifyContent="flex-end"
										spacing={2}
									>
										<Grid item>
											<Button
												onClick={() => handleClose(formikProps.dirty)

												}
												color="primary"
												variant="outlined"
											>
												Cancel
											</Button>
										</Grid>
										<Grid item>
											<Button
												disabled={
													!(
														formikProps.dirty ||
														Boolean(channelData?.cadence) !== usingCadence
													) ||
													(usingCadence && !formikProps.values?.cadence?.length)
												}
												onClick={formikProps.handleSubmit}
												color="primary"
												variant="contained"
											>
												Save
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Box>
						)}
					</Formik>
				</DialogContent>
			</Dialog>
		</>
	);
}

let ScriptSelect = memo(
	({ touched, value, onChange, onBlur, error, dialingScripts }) => (
		<FormControl style={{ width: '100%', marginTop: '12px' }} required>
			<InputLabel error={error && touched}>Dialing Script</InputLabel>
			<Select
				label="Dialing Script"
				color="primary"
				margin="dense"
				name="dialingScript"
				required
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				error={error && touched}
			>
				{dialingScripts.map((script, index) => (
					<MenuItem key={index} value={script.scriptName}>
						{script.scriptName}
					</MenuItem>
				))}
			</Select>
			{error && touched && <FormHelperText error>{error}</FormHelperText>}
		</FormControl>
	),
	(previousProps, nextProps) =>
		previousProps.classes === nextProps.classes &&
		previousProps.touched === nextProps.touched &&
		previousProps.value === nextProps.value &&
		previousProps.error === nextProps.error &&
		previousProps.dialingScripts === nextProps.dialingScripts,
);

ScriptSelect.displayName = 'ScriptSelect';
